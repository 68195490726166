import $ from 'jquery';
import type { OnlineMag, ModuleId } from './@types/pagetiger';
import {
  DEFAULT_REQUIRED_INTERACTIVITY_MESSAGE,
  HTML_PAGE_TURN,
  MANDATORY_CLASS,
  MODULES,
  PAGE_TURN,
  STACKABLE
} from './constants';
import {
  delay,
  getFirstPageWithIncompleteRequiredModules,
  getI18nByKey,
  getModuleSelector,
  getRequiredModuleMessageAndSelectors,
  getRequiredModulesForCurrentPages,
  openWindow,
  showRequiredInteractivityModal
} from './utils';
import { currentVisiblePageIndexes } from './page-helpers';
import { OnlineMagPage } from './mag-page';

/**
 * @param {'id' | 'title'} propertyName - Usually `id` or `title`
 * @returns {any|null}
 */
const getBy = (propertyName: 'id' | 'title') => (items, value) =>
  items.find(item => item[propertyName] == value);

const getByTitle = getBy('title');
const getByID = getBy('id');

/**
 * Returns all the modules of a specific type
 */
const getAllModulesOfType = (pageTurn: OnlineMag, moduleType: ModuleId): any[] => {
  switch (moduleType) {
    case MODULES.LINK:
      return pageTurn.allOnlineMagPageLinks;
    case MODULES.VIDEO:
      return pageTurn.allOnlineMagPageVideos;
    case MODULES.POLL_ANSWER:
      return pageTurn.allOnlineMagPagePollAnswers;
    case MODULES.ARTICLE:
      return pageTurn.allOnlineMagPageArticles;
    case MODULES.IFRAME:
      return pageTurn.allOnlineMagPageIFrames;
    case MODULES.JUMP:
      return pageTurn.allOnlineMagPageJumps;
    case MODULES.COMP:
      return pageTurn.allOnlineMagPageComps;
    case MODULES.GALLERY:
      return pageTurn.allOnlineMagPageGalleries;
    case MODULES.FORWARD:
      return pageTurn.allOnlineMagPageForwards;
    case MODULES.SHARE:
      return pageTurn.allOnlineMagPageShares;
    case MODULES.CHART:
      return pageTurn.allOnlineMagPageCharts;
    default:
      return [];
  }
};

function linkClicked(elementID: string, moduleType: ModuleId, moduleID: number): void {
  const pageTurn = window.ptiGetInstance();
  const modules = getAllModulesOfType(pageTurn, moduleType);
  const module = getByID(modules, moduleID);

  if ($.fn.ptibox.isVisible()) {
    return window.ptiClosePopupLinkClicked(elementID, moduleType, moduleID);
  }

  pageTurn.ui.pauseVideos();

  if (module) {
    if (moduleType === MODULES.LINK) {
      openWindow(module.url, module.target);
    }

    module?.clicked();
  }

  if (pageTurn.config.onLinkClicked) {
    pageTurn.config.onLinkClicked.call(pageTurn, pageTurn.containerElementID, moduleType, moduleID);
  }
}

const linkClickedTitle = (
  elementID: string,
  moduleType: ModuleId,
  pageIndex: number,
  moduleTitle: string
): void => {
  const pageTurn = window.ptiGetInstance();
  const page: OnlineMagPage = pageTurn.onlineMagPages.items[pageIndex];
  const items = page.modules.filter(module => module.moduleType === moduleType);
  const module = getByTitle(items, moduleTitle);

  if (module) {
    window.ptiLinkClicked(elementID, moduleType, module.id);
  }
};

const previousPage = (elementID: string, suppressChainOnPageShow = false): void => {
  const pageTurn = window.ptiGetInstance();
  const scrollTop = document.documentElement.scrollTop || 0;
  const { scrollTopOnPaging } = pageTurn.config;

  if ($.fn.ptibox.isVisible()) {
    window.closePopUp();
  }

  pageTurn.suppressChainOnPageShow = suppressChainOnPageShow;

  if (scrollTopOnPaging && scrollTop > 0) {
    $('html').animate(
      { scrollTop: 0 },
      {
        complete() {
          previousPage(elementID);
        },
        duration: 500,
        easing: 'swing'
      }
    );
  } else {
    pageTurn.ui.previousPage();
  }

  document.getElementById('main').focus();
};

const goToPage = (
  _elementID: string | null,
  pageIndex: number,
  suppressChainOnPageShow = false,
  preventRestoreFocus = false
): void => {
  let showModalDelay = 0;
  const objPageTurn = window.ptiGetInstance();
  const { currentPageIndex, config, onlineMagPages, zoom } = objPageTurn;
  const pageIndexes = currentVisiblePageIndexes(objPageTurn);

  objPageTurn.suppressChainOnPageShow = suppressChainOnPageShow;

  if (pageIndexes.includes(pageIndex)) {
    $.fn.ptibox.hideLoading();
  }

  if ($.fn.ptibox.isVisible()) {
    window.closePopUp();
  }

  if (pageIndex > currentPageIndex && objPageTurn.ui) {
    // Check that the page we're going to is on the right hand side. If so, we don't want to
    // include the left hand side in `pagesInBetween`
    const wantOddOrEven = config.alwaysOpened ? 1 : 0;
    const isRightHandSide = !zoom && pageIndex % 2 === wantOddOrEven;
    const sliceTo = isRightHandSide ? pageIndex - 1 : pageIndex;
    const pagesToCheck = onlineMagPages.items.slice(0, sliceTo);
    const page = getFirstPageWithIncompleteRequiredModules(pagesToCheck);
    const currentlyVisiblePages = currentVisiblePageIndexes(objPageTurn);
    let focusableModule = null;

    if (page) {
      focusableModule = document.querySelector(`[data-page-index="${page.pageIndex}"] .${MANDATORY_CLASS}`);

      if (!currentlyVisiblePages.includes(page.pageIndex)) {
        showModalDelay = 900;
        objPageTurn.ui.gotoPage(page.pageIndex);
      }

      const { message, unfinishedModuleSelectors } = getRequiredModuleMessageAndSelectors(page);

      setTimeout(
        () =>
          showRequiredInteractivityModal(
            objPageTurn,
            message,
            unfinishedModuleSelectors,
            focusableModule
          ),
        showModalDelay
      );

      return;
    }

    if (currentPageIndex !== pageIndex) {
      objPageTurn.ui.gotoPage(pageIndex);
    }

  } else {
    objPageTurn.ui.gotoPage(pageIndex);
  }
};

const nextPage = (_elementID: string, suppressChainOnPageShow = false): void => {
  const pageTurn = window.ptiGetInstance();
  const visiblePageIndexes = currentVisiblePageIndexes(pageTurn);
  const requiredModules = getRequiredModulesForCurrentPages(
    visiblePageIndexes,
    pageTurn.onlineMagPages.items
  );
  const isPageTurn =
    pageTurn.viewingInterface === PAGE_TURN || pageTurn.viewingInterface === HTML_PAGE_TURN;
  const { scrollTop } = document.documentElement;
  const { scrollTopOnPaging } = pageTurn.config;

  if ($.fn.ptibox.isVisible()) {
    window.closePopUp();
  }

  pageTurn.suppressChainOnPageShow = suppressChainOnPageShow;

  if (requiredModules.length > 0) {
    const [{ pageMessage, modules }] = requiredModules;
    // By working on the first index we're always getting the correct message to display. If
    // there's a left and right page, the first index is left. If there is only required
    // modules on the right page, that will still be in the first index.
    const moduleMessages = modules
      // A poll answer can have multiple modules on the page (all with the same message, only display one).
      .filter((mod, index, arr) => arr.findIndex(el => el.pollID === mod.pollID) === index)
      .reduce(
        (acc, mod) =>
          mod.mandatoryMessage.trim() === '' ? acc : `${acc}<p>${mod.mandatoryMessage}</p>`,
        ''
      );

    const modalMessage =
      pageMessage === '' && moduleMessages === ''
        ? getI18nByKey(DEFAULT_REQUIRED_INTERACTIVITY_MESSAGE, getI18nByKey("Required.Interactions.Title"), getI18nByKey("Required.Interactions.Message"))
        : pageMessage + moduleMessages;

    const unfinishedModuleSelectors = modules.map(getModuleSelector).join(', ');

    showRequiredInteractivityModal(pageTurn, modalMessage, unfinishedModuleSelectors, null);

    if (isPageTurn) {
      pageTurn.ui.forcePreventFolds = false;
    }

    return;
  }

  if (scrollTopOnPaging && scrollTop > 0 && isPageTurn) {
    $('html').animate(
      { scrollTop: 0 },
      {
        complete() {
          window.ptiNextPage(_elementID, suppressChainOnPageShow);
        },
        duration: 500,
        easing: 'swing'
      }
    );
  } else {
    pageTurn.ui.nextPage();
  }

  document.getElementById('main').focus();
};

const doublePageView = () => {
  const pageTurn = window.ptiGetInstance();
  const { zoom } = pageTurn;
  const isStackable = pageTurn.viewingInterface === STACKABLE;
  const $body = $('body');

  if (zoom) {
    if (!isStackable && pageTurn.config.scrollTopOnPaging && $body.get(0).scrollTop > 0) {
      $body.animate(
        { scrollTop: 0 },
        {
          complete() {
            window.ptiDoublePageView();
          },
          duration: 500,
          easing: 'swing'
        }
      );
    } else {
      pageTurn.doublePageView();
    }
  }
};

const singlePageView = () => {
  const pageTurn = window.ptiGetInstance();
  const { config, zoom } = pageTurn;
  const isStackable = pageTurn.viewingInterface === STACKABLE;
  const $body = $('body');

  if (!zoom) {
    if (!isStackable && config.scrollTopOnPaging && $body.get(0).scrollTop > 0) {
      $body.animate(
        { scrollTop: 0 },
        {
          complete() {
            window.ptiSinglePageView();
          },
          duration: 500,
          easing: 'swing'
        }
      );
    } else {
      pageTurn.singlePageView();
    }
  }
};

const gotoPageIfOnCover = (elementID: string, pageIndex: number): void => {
  var pageTurn = window.ptiGetInstance();

  if (pageTurn.currentPageIndex === 0) {
    goToPage(elementID, pageIndex);
  }
};

const gotoPageClickLink = (
  elementID: string,
  pageIndex: number,
  linkType: number,
  linkId: number
) => {
  var pageTurn = window.ptiGetInstance();

  pageTurn.gotoPageClickLinkPageNo = pageIndex;
  pageTurn.gotoPageClickLinkLinkType = linkType;
  pageTurn.gotoPageClickLinkLinkID = linkId;

  goToPage(elementID, pageIndex);
};

const closePopupLinkClicked = (elementID: string, type: ModuleId, id: number): void => {
  $().ptibox.close(function closeModal() {
    delay(500).then(() => {
      linkClicked(elementID, type, id);
    });
  });
};

const closePopupLinkClickedUsingTitle = (
  elementID: string,
  type: ModuleId,
  pageIndex: number,
  moduleTitle: string
) => {
  $().ptibox.close(function closeModal() {
    delay(500).then(() => {
      window.ptiLinkClickedUsingTitle(elementID, type, pageIndex, moduleTitle);
    });
  });
};

export {
  closePopupLinkClicked,
  closePopupLinkClickedUsingTitle,
  doublePageView,
  goToPage,
  gotoPageClickLink,
  gotoPageIfOnCover,
  linkClicked,
  linkClickedTitle,
  nextPage,
  previousPage,
  singlePageView
};
