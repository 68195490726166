import type { OnlineMag } from './@types/pagetiger';
import { getDomElement, getDomElements, getI18nByKey, makeHtml } from './utils';

export type NotificationCount = number;

/**
 * Simulates fetching notifications from a server.
 * @returns {Promise<number>} A promise that resolves to a random number of notifications.
 */
function fakeFetchNotifications(): Promise<number> {
    return new Promise((resolve) => {
        setTimeout(() => {
            const randomNum = Math.floor(Math.random() * 10);
            resolve(randomNum);
        }, 1000);
    });
}

/**
 * Displays the notification count against the toolbar account icon.
 * @param {number} count - The number of notifications.
 */
function showNotificationCount(count: number = 0): void {
  const linkAriaLabel =
    count === 1
      ? getI18nByKey('Notifications.MyAccount.NewItem.Single', `${count}`)
      : getI18nByKey('Notifications.MyAccount.NewItem.Multiple', `${count}`);

  // Append a count badge alongside toolbar "Account" icon.
  getDomElements('#my-account').map(el => {
    el.ariaLabel = linkAriaLabel;
    el.appendChild(makeHtml(`<div class="badge-count"><span>${count}</span></div>`));
  });

  // Append a count badge alongside the "My Account" link.
  getDomElement('.my-account-link').map(el => {
    el.ariaLabel = linkAriaLabel;
    el.appendChild(makeHtml(`<div class="badge-count"><span>${count}</span></div>`));
  });
}

function showNotification(count: number = 0): void {
  const title =
    count === 1
      ? getI18nByKey('Notifications.BadgesToEarn.Title.Single', `${count}`)
      : getI18nByKey('Notifications.BadgesToEarn.Title.Multiple', `${count}`);

  getDomElement('#notifications').map(el => {
    el.appendChild(
      makeHtml(`
                <div 
                    class="notification notification-neutral" 
                    role="alert" 
                    aria-labelledby="notification-title" 
                    aria-describedby="notification-message"
                    aria-live="assertive"
                    tabindex="-1"                                
                >
                    <i class="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="tabler-icon tabler-icon-message ">
                            <path d="M8 9h8"></path>
                            <path d="M8 13h6"></path>
                            <path d="M18 4a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-5l-5 3v-3h-2a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12z"></path>
                        </svg>
                    </i>                    
                    <div class="main">
                        <div class="title-wrapper">
                            <span class="title" id="notification-title">${title}</span>
                            <button type="button" class="dismiss-notification" aria-label="Dismiss">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="tabler-icon tabler-icon-x ">
                                    <path d="M18 6l-12 12"></path><path d="M6 6l12 12"></path>
                                </svg>                            
                            </button>
                        </div>
                        <p class="message" id="notification-message">${ getI18nByKey('Notifications.BadgesToEarn.Message') }</p>
                    </div>
                </div>
        `));                            
    });

    getDomElements('.dismiss-notification').map(el => {
        el.addEventListener('click', (e) => dismissNotification(e));
    });
}

function dismissNotification(e: Event): void {
    const el = e.target as HTMLElement;
    el.closest('.notification')?.remove();
}

export async function getNotifications(pageTurn: OnlineMag): Promise<void> {
    const hasMyAccountV2 = pageTurn.config.features.myAccountV2 || false;
    let notificationCount: number = 0;

    if (hasMyAccountV2) {
        try {
            notificationCount = await fakeFetchNotifications();
            
            if (notificationCount > 0) {
                showNotificationCount(notificationCount);
                showNotification(notificationCount);
            }
        } catch (error) {
            console.error('Error fetching notifications:', error);
        }
    }    
}