// @ts-check
/* eslint-disable import/no-unassigned-import, max-classes-per-file, no-inline-comments, max-lines, import/max-dependencies, max-statements, max-lines-per-function */
import './css/index.css';

import 'regenerator-runtime/runtime.js';
import 'core-js/stable';
import $ from 'jquery';
import 'jquery-migrate';
import 'superfish';
import 'jquery-hoverintent';
import 'jquery.easing';
import 'document.contains';
import 'url-polyfill';
import 'focus-visible';
import 'wicg-inert/dist/inert';
import * as RemoteData from '@roebuk/remote-data';
import Tooltip from 'tooltip.js';
import Cookies from 'js-cookie';
import Maybe from 'crocks/Maybe';
import chain from 'crocks/pointfree/chain';
import compose from 'crocks/helpers/compose';
import either from 'crocks/pointfree/either';
import splitEvery from 'ramda/src/splitEvery';
import view from 'ramda/src/view';
import curry from 'ramda/src/curry';
import lensPath from 'ramda/src/lensPath';
import { throttle } from 'throttle-debounce';

import {
  closePopupLinkClicked,
  closePopupLinkClickedUsingTitle,
  doublePageView,
  goToPage,
  gotoPageClickLink,
  gotoPageIfOnCover,
  linkClicked,
  linkClickedTitle,
  nextPage,
  previousPage,
  singlePageView
} from './public-api';
import { resetCorners } from './corner-fold';
import * as videoEvents from './video-events';
import './lightbox';
import './lightbox-message';
import { responsiveMenu } from './responsive-menu';
import { renderMenu } from './components/Menu';
import * as menu from './menu';
import {
  attractModeStart,
  attractModeStillActive,
  containsHtml,
  delay,
  getCustomChartSizes,
  getBy,
  getDomElement,
  getI18nByKey,
  getRequestedInitialPage,
  getLayout,
  getFirstPageIndexWithIncompleteRequiredModules,
  getLeftRightMarginSize,
  getModuleSelector,
  getOrientation,
  getPageShadow,
  getRequiredModulesForCurrentPages,
  getTestSuffix,
  getValidatedEmail,
  handleInvalidFormElement,
  hasVirtualKeyboard,
  htmlEncode,
  hideLeftRightArrows,
  isJpgView,
  isPageTurnMode,
  lowestVisiblePageIndex,
  noOp,
  openWindow,
  outputFontsStyles,
  parseHtmlString,
  parseJson,
  parseUrl,
  prefersReducedMotion,
  rewriteToUseSSL,
  safeNumberFromString,
  sendBeacon,
  showRequiredInteractivityModal,
  isTrue,
  isFunction,
  defaultToEmptyString
} from './utils';
import { getSearchResults, getSearchResultsMarkup } from './search';
import { getIssueListMarkup, getIssueVersions } from './issue-list';
import * as auth from './auth';
import * as notifications from './notifications';

import { getViewInterface } from './viewing-device';
import { Analytics } from './analytics';
import { parseAnalytics, parseDocumentSettings } from './api-utils';
import { currentVisiblePageIndexes, getUpdatedUrl } from './page-helpers';
import { pageTigerAPI, validateGUID } from './page-tiger-api';
import { ErrorReporter } from './error-reporter';
import { getToolbarItems } from './toolbar';
import {
  googleAnalyticsMarkUp,
  facebookAnalyticsMarkUp,
  renderToolBar,
  renderToolbarLink,
  pagingButton,
  renderToolbarMenu
} from './widgets';
import * as constants from './constants';
import { OnlineMagPage, OnlineMagPages } from './mag-page';
import { setupDesignerMessaging } from './designer-message-events';

import { getInteractivityStyles, getToolbarStyles } from './styles';
import { getCookiePreferences } from './cookie';
import { getCookieManager } from './cookie-manager';
import { getSkipLinks } from './accessibility';
import { isGuid } from './guid';
import {
  DEFAULT_REQUIRED_INTERACTIVITY_MESSAGE,
  MODULE_SUB_TYPES,
  VIDEO_SUB_TYPES
} from './constants';

/** @typedef {import('./@types/pagetiger').ServerResponse} ServerResponse */
/** @typedef {import('./@types/pagetiger').Page} Page */
/** @typedef {import('./@types/pagetiger').CookieValues} CookieValues */
/** @typedef {import('./@types/pagetiger').Config} Config */
/** @typedef {import('./@types/pagetiger').PartialConfig} PartialConfig */
/** @typedef {import('./@types/pagetiger').DocumentSettings} DocumentSettings */
/** @typedef {import('./@types/pagetiger').CookiePreferences} CookiePreferences */
/** @typedef {import('./@types/pagetiger').ModuleId} ModuleType */
/** @typedef {import('./@types/pagetiger').OnlineMag} OnlineMag */
/** @typedef {import('./@types/pagetiger').FullModule} FullModule */
/** @typedef {import('./@types/pagetiger').UI} UI */
/** @typedef {import('./@types/pagetiger').ShareOptions} ShareOptions */
/** @typedef {import('./@types/widgets').ToolbarLink} ToolbarLink */
/** @typedef {import('./@types/widgets').ToolbarItems} ToolbarItems */
/** @typedef {import('./@types/widgets').ToolbarButton} ToolbarButton */
/** @typedef {import('./toolbar').ToolbarMenuItem} ToolbarMenuItem */
/** @typedef {import('./issue-list').IssueList} IssueList */
/** @typedef {import('./interactivity/link').OnlineMagPageLink} OnlineMagPageLink */
/** @typedef {import('./interactivity/poll-answer').OnlineMagPagePollAnswers} OnlineMagPagePollAnswers */
/** @typedef {import('./interactivity/article').OnlineMagPageArticle} OnlineMagPageArticle */
/** @typedef {import('./interactivity/iframe').OnlineMagPageIFrame} OnlineMagPageIFrame */
/** @typedef {import('./interactivity/chart').OnlineMagPageChart} OnlineMagPageChart */
/** @typedef {import('./interactivity/share').OnlineMagPageShare} OnlineMagPageShare */
/** @typedef {import('./interactivity/forward').OnlineMagPageForward} OnlineMagPageForward */
/** @typedef {import('./interactivity/gallery').OnlineMagPageGallery} OnlineMagPageGallery */
/** @typedef {import('./interactivity/page-jump').OnlineMagPageJump} OnlineMagPageJump */
/** @typedef {import('./interactivity/competition').Competition} Competition */
/** @typedef {import('./interactivity/video').OnlineMagPageVideo} OnlineMagPageVideo */
/** @typedef {import('./search').SearchResults} SearchResults */

// Initialise API info first - errorReporter requires version to be set
window.API_VERSION = '22';
window.API_PATH = `/api/V${window.API_VERSION}`;

window.errorReporter = new ErrorReporter(
  window.disableErrorReporting,
  window.extraBlacklistURLs,
  window.extraBlacklistErrors
);

const {
  COOKIE_MANAGERS,
  DESIGNER_MESSAGE_EVENTS,
  MANDATORY_CLASS,
  MODULES,
  HIGH_CONTRAST,
  HTML_PAGE_TURN,
  HTML_STACKABLE,
  PAGE_TURN,
  STACKABLE,
  TOOLBAR_POSITION
} = constants;

const { userAgent } = navigator;
const GLOBAL_ARIA_LIVE_CLASS = 'js-global-aria-live';
const GLOBAL_MENU_MOUNTING_POINT_CLASS = 'js-menu-mounting-point';
// @ts-ignore
const isIE11 = Boolean(window.MSInputMethodContext) && Boolean(document.documentMode);

// Another IE hack for the new Menu. Remove when IE11 support is dropped
// @ts-ignore
(function () {
  if (typeof window.CustomEvent === 'function') return false;

  function CustomEvent(event, params) {
    params = params || { bubbles: false, cancelable: false, detail: undefined };
    var evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
    return evt;
  }

  CustomEvent.prototype = window.Event.prototype;

  // @ts-ignore
  window.CustomEvent = CustomEvent;
  window.Event = CustomEvent;
})();

window.$ = $;
window.jQuery = $;
window.closePopUp = $().ptibox.close;

window.moduleTypeLink = MODULES.LINK;
window.moduleTypePollAnswer = MODULES.POLL_ANSWER;
window.moduleTypeArticle = MODULES.ARTICLE;
window.moduleTypeIFrame = MODULES.IFRAME;
window.moduleTypeVideo = MODULES.VIDEO;
window.moduleTypeJump = MODULES.JUMP;
window.moduleTypeComp = MODULES.COMP;
window.moduleTypeGallery = MODULES.GALLERY;
window.moduleTypeForward = MODULES.FORWARD;
window.moduleTypeShare = MODULES.SHARE;
window.containerID = 'divPtiContainer';
window.pt = pageTigerAPI;
window.ptiThemeFolder = `${window.API_PATH}/Themes`;
window.ptiWS = `${window.API_PATH}/PTI.aspx`;
window.ptiProtocolSSL = 'https://';
// Public API
window.getByTitle = getBy('title');
window.getByID = getBy('id');

window.ptiIsTablet = userAgent.match(/tablet|mobile/gi) && !userAgent.match(/tablet pc/gi);

window.ptiAttractModeStarted = false;
window.ptiAttractModeLastInteraction = new Date();
window.ptiAttractModeFirstPageViewed = false;

/**
 * @param {number} min
 * @param {number} max
 * @param {number} val
 * @returns {number}
 */
function clamp(min, max, val) {
  return val > max ? max : val < min ? min : val;
}
/**
 * @param {HTMLVideoElement} video
 * @returns {boolean}
 */
const isVideoPlaying = video =>
  video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2;

// If the user is on an Iphone enable the user to click the
// video for it to start playing.
if (navigator.platform === 'iPhone') {
  window.addEventListener('click', ({ target }) => {
    if (target instanceof HTMLVideoElement) {
      if (!isVideoPlaying(target)) {
        target.play();
      }
    }
  });
}

document.addEventListener('DOMContentLoaded', () => {
  if (!('ontouchstart' in document.documentElement)) {
    document.body.classList.add('no-touch');
  }
});

/**
 * @param {string} fnName
 * @returns {() => void}
 */
const warningDropThrough = fnName => () =>
  console.warn(`'${fnName}' dropped through 'mapFunctionNameToFunction'.`);

/**
 * @param {string} fnName
 * @returns {() => void | undefined}
 */
const mapFunctionNameToFunction = fnName => {
  const requestedFunction = window[fnName];

  if (isFunction(requestedFunction)) {
    return requestedFunction;
  }

  return warningDropThrough(fnName);
};

/** @returns {OnlineMag} */
const getInstance = () => window.pageTurn;

window.ptiGetInstance = getInstance;

window.scrollTop = function scrollTop(callback) {
  $('html').animate({ scrollTop: 0 }, { complete: callback, duration: 300, easing: 'swing' });
};

/**
 * @param {OnlineMag} pageTurn
 * @returns {boolean}
 */
const checkRequiredInteractivityAndTakeUserToFurthestPage = pageTurn => {
  var lowestVisibleIndex = -1;
  var highestCompletedPageIndex = -1;
  var foundOutstandingInteractivity = false;

  // We will use this to check all the pages with an index less than this for outstanding content.
  lowestVisibleIndex = lowestVisiblePageIndex(pageTurn);
  var currentlyVisiblePages = currentVisiblePageIndexes(pageTurn);

  // Get important un completed modules on the current page
  for (let pageIndex = 0; pageIndex < lowestVisibleIndex; ++pageIndex) {
    const modLength = pageTurn.onlineMagPages.items[pageIndex].modules.length;

    for (let modIndex = 0; modIndex < modLength; ++modIndex) {
      const objModule = pageTurn.onlineMagPages.items[pageIndex].modules[modIndex];

      if (objModule.mandatory === true && objModule.isComplete === false) {
        foundOutstandingInteractivity = true;
        break;
      }
    }

    if (foundOutstandingInteractivity === false) {
      highestCompletedPageIndex = pageIndex;
    } else {
      break;
    }
  }

  if (foundOutstandingInteractivity === true) {
    // Goto to page index
    pageTurn.gotoPageClickLinkPageNo = -1;
    pageTurn.gotoPageClickLinkLinkType = -1;
    pageTurn.gotoPageClickLinkLinkID = -1;
    pageTurn.checkOutstandingInteractivity = true;

    const targetPageIndex = highestCompletedPageIndex + 1;

    // This timeout is required, otherwise the page wont turn back correctly.
    if (!currentlyVisiblePages.includes(targetPageIndex)) {
      window.setTimeout(function goToPageTimeout() {
        window.ptiGotoPage(pageTurn.containerElementID, targetPageIndex, true);
      }, 1);
    } else {
      return true;
    }

    return false;
  }

  return true;
};

/* eslint-disable complexity */
/**
 * @param {OnlineMag} pageTurn - DOM selector
 * @param {function} callback - Callback
 * @returns {void}
 */
const checkRequiredInteractivityOnCurrentPage = (pageTurn, callback = () => {}) => {
  if (pageTurn.viewingInterface === STACKABLE) {
    return;
  }

  const visiblePageIndexes = currentVisiblePageIndexes(pageTurn);
  const requiredModules = getRequiredModulesForCurrentPages(visiblePageIndexes, pageTurn.pages);

  if (requiredModules.length) {
    const [{ pageMessage, modules }] = requiredModules;
    // By working on the first index we're always getting the correct message to display. If
    // there's a left and right page, the first index is left. If there is only required
    // modules on the right page, that will still be in the first index.
    const moduleMessages = modules
      // A poll answer can have multiple modules on the page (all with the same message, only display one).
      .filter((mod, index, arr) => arr.findIndex(el => el.pollID === mod.pollID) === index)
      .reduce(
        (acc, mod) =>
          mod.mandatoryMessage.trim() === '' ? acc : `${acc}<p>${mod.mandatoryMessage}</p>`,
        ''
      );
    const modalMessage =
      pageMessage === '' && moduleMessages === ''
        ? getI18nByKey(
            DEFAULT_REQUIRED_INTERACTIVITY_MESSAGE,
            getI18nByKey('Required.Interactions.Title'),
            getI18nByKey('Required.Interactions.Message')
          )
        : pageMessage + moduleMessages;

    const unfinishedModuleSelectors = modules.map(getModuleSelector).join(', ');

    showRequiredInteractivityModal(pageTurn, modalMessage, unfinishedModuleSelectors, null);
  } else {
    callback();
  }
};

window.ptiClosePopupGotoPage = function ptiClosePopupGotoPage(elementID, pageIndex) {
  window.ptiClosePopup();
  window.ptiGotoPage(elementID, pageIndex);
};

window.ptiClosePopupGotoPageSlow = function ptiClosePopupGotoPageSlow(elementID, pageIndex) {
  window.ptiClosePopup();
  window.ptiGotoPageSlow(elementID, pageIndex);
};

window.ptiSwitchPageView = () => {
  const { zoom } = getInstance();

  zoom ? doublePageView() : singlePageView();
};

window.ptiNextPage = nextPage;
window.ptiPreviousPage = previousPage;
window.ptiGotoPage = goToPage;
window.ptiGotoPageSlow = goToPage;
window.ptiSinglePageView = singlePageView;
window.ptiDoublePageView = doublePageView;
window.ptiLinkClicked = linkClicked;
window.ptiLinkClickedUsingTitle = linkClickedTitle;
window.ptiGotoPageClickLink = gotoPageClickLink;
window.ptiClosePopupLinkClicked = closePopupLinkClicked;
window.ptiClosePopupLinkClickedUsingTitle = closePopupLinkClickedUsingTitle;
window.ptiTools = window.ptiSearch;
window.ptiGotoPageIfOnCover = gotoPageIfOnCover;
window.ptiCookie = () => window.ptiGetInstance().cookieManager.openCookieModal();

window.ptiClosePopup = function ptiClosePopup(callback = () => {}) {
  $().ptibox.close(callback);
};

window.ptiEnterPageNumber = function ptiEnterPageNumber(_elementID) {
  getInstance().enterPageNumber();
};

window.ptiContents = function ptiContents(_elementID) {
  getInstance().showContents();
};

window.ptiCloseContentsGotoPage = function ptiCloseContentsGotoPage(_elementID, pageIndex) {
  getInstance().closeContentsGotoPage(pageIndex);
};

window.ptiCloseSearchGotoPage = function ptiCloseSearchGotoPage(_elementID, pageIndex) {
  getInstance().closeSearchGotoPage(pageIndex);
};

window.ptiIssues = function ptiIssues(_elementID) {
  getInstance().showIssues();
};

window.ptiDownloadPDF = function ptiDownloadPDF() {
  getInstance().downloadPDF();
};

window.ptiSearch = function ptiSearch(menuID) {
  getInstance().showSearch(menuID);
};

window.ptiPauseVideos = function ptiPauseVideos() {
  getInstance().ui.pauseVideos();
};

window.ptiShare = function ptiShare() {
  getInstance().showShare();
};

window.ptiPrint = function ptiPrint() {
  getInstance().showPrint();
};

/* eslint-disable complexity */

/**
 * @param {string} elementID
 * @param {Page|null} leftPage
 * @param {Page|null} rightPage
 * @returns {void}
 */
window.ptiPagesViewed = function ptiPagesViewed(elementID, leftPage, rightPage) {
  const pageTurn = getInstance();
  const { onlineMagPages } = pageTurn;

  if (checkRequiredInteractivityAndTakeUserToFurthestPage(pageTurn)) {
    const { NAV_ARROW_CLASS } = constants;
    const {
      gotoPageClickLinkLinkType,
      gotoPageClickLinkLinkID,
      gotoPageClickLinkPageNo,
      onlineMagSideNavBehaviour
    } = pageTurn;

    pageTurn.pagesViewed(leftPage, rightPage);

    const [hideLeft, hideRight] = hideLeftRightArrows(
      leftPage,
      rightPage,
      onlineMagPages.length - 1
    );

    /**
     * @param {Page} page
     */
    const markOnPageInteractivityAsViewed = page => {
      if (!page) {
        return;
      }

      page.modules.forEach(module => {
        if (module.moduleType === 11 && module.subType === 'Chart') {
          delay(1000).then(() => {
            $.ajax({
              url: `${pageTurn.baseUrl}?M=14&VID=${pageTurn.onlineMagIssueVisitID}&CID=${module.id}`,
              dataType: 'jsonp'
            }).catch(console.warn);
          });
        }
      });
    };

    markOnPageInteractivityAsViewed(leftPage);
    markOnPageInteractivityAsViewed(rightPage);

    $(`.${NAV_ARROW_CLASS}.mod-prev`).attr('aria-hidden', hideLeft.toString());
    $(`.${NAV_ARROW_CLASS}.mod-next`).attr('aria-hidden', hideRight.toString());

    // Animate side arrows after paging
    if (onlineMagSideNavBehaviour === 'on hover and after paging') {
      const $sideNav = $(`.${NAV_ARROW_CLASS}.side-nav`);
      const wideClass = 'mod-wide';

      $sideNav.addClass(wideClass);

      setTimeout(() => {
        $sideNav.removeClass(wideClass);
      }, 500);
    }

    if (
      gotoPageClickLinkLinkType > -1 &&
      (gotoPageClickLinkPageNo == leftPage?.pageIndex ||
        gotoPageClickLinkPageNo == rightPage?.pageIndex)
    ) {
      window.ptiLinkClicked(elementID, gotoPageClickLinkLinkType, gotoPageClickLinkLinkID);

      pageTurn.gotoPageClickLinkPageNo = -1;
      pageTurn.gotoPageClickLinkLinkType = -1;
      pageTurn.gotoPageClickLinkLinkID = -1;
    }

    if (pageTurn.config.onPagesViewed) {
      pageTurn.config.onPagesViewed.call(
        pageTurn,
        pageTurn.containerElementID,
        leftPage?.pageIndex ?? 0,
        rightPage?.pageIndex ?? 0
      );
    }

    if (!window.ptiAttractModeFirstPageViewed) {
      window.ptiAttractModeFirstPageViewed = true;

      if (pageTurn.config.attractMode) {
        window.setTimeout(() => {
          attractModeStart(elementID);
        }, pageTurn.config.attractModeStartAfterSeconds * 1000);
      }
    }

    if (pageTurn.checkOutstandingInteractivity === true) {
      pageTurn.checkOutstandingInteractivity = false;

      window.setTimeout(function fnDelay() {
        checkRequiredInteractivityOnCurrentPage(pageTurn);
      }, 1);
    }
  }

  if (!isJpgView(pageTurn.viewingInterface)) {
    window.history.replaceState(
      null,
      '',
      getUpdatedUrl(pageTurn, new URL(window.location.href), rightPage)
    );
  }
};

window.ptiVideoCompleted = function ptiVideoCompleted(_elementID, videoID) {
  var objPageTurn = getInstance();
  var objVideo = window.getByID(objPageTurn.allOnlineMagPageVideos, videoID);

  if (objVideo.onComplete.length > 0) {
    window.setTimeout(objVideo.onComplete, 100);
  }
};

window.ptiHookupLeftRightArrows = function ptiHookupLeftRightArrows(elementID) {
  const { onlineMagPages, onlineMagShowPaging } = getInstance();

  if (onlineMagShowPaging && onlineMagPages.length > 1) {
    $(document)
      .off('keydown.box')
      .on('keydown.box', function onKeyDown(e) {
        const isCornerActive = document.querySelector('[active-corner]');
        const delayMs = isCornerActive ? 500 : 0;

        if (isCornerActive) {
          resetCorners();
        }

        switch (e.key) {
          case 'ArrowDown':
          case 'ArrowRight':
            e.preventDefault();

            return delay(delayMs).then(() => window.ptiNextPage(elementID));

          case 'ArrowUp':
          case 'ArrowLeft':
            e.preventDefault();

            return delay(delayMs).then(() => window.ptiPreviousPage(elementID, false));

          default:
            return Promise.resolve();
        }
      });
  }
};

/* eslint-disable max-lines-per-function */
/**
 * @implements {OnlineMag}
 */
class OnlineMagIssue {
  /**
   * @this {OnlineMag}
   * @param {string} containerElementID - DOM selector
   * @param {Config} conf
   * @param {string} documentGuid - The guid for the document
   */
  constructor(containerElementID, conf, documentGuid) {
    this.gotDocumentData = this.gotDocumentData.bind(this);
    this.finishVisit = this.finishVisit.bind(this);
    this.enterPageNumberNow = this.enterPageNumberNow.bind(this);
    this.shareNow = this.shareNow.bind(this);
    this.updatePageSize = this.updatePageSize.bind(this);
    this.printSelection = this.printSelection.bind(this);
    this.loadDocument = this.loadDocument.bind(this);
    this.downloadPDF = this.downloadPDF.bind(this);
    this.downloadSelection = this.downloadSelection.bind(this);
    this.searchResults = this.searchResults.bind(this);
    this.promptForEmailAndOrPassword = this.promptForEmailAndOrPassword.bind(this);
    this.checkReaderLoggedIn = this.checkReaderLoggedIn.bind(this);
    this.checkSsoLoggedIn = this.checkSsoLoggedIn.bind(this);
    this.checkEmployeeLoggedIn = this.checkEmployeeLoggedIn.bind(this);
    this.showPublication = this.showPublication.bind(this);
    this.changedSincePreviousResize = false;
    this.searchNow = throttle(400, this.searchNow.bind(this));

    this.sizes = {
      singlePage: {
        pageHeight: 0,
        pageWidth: 0
      },
      doublePage: {
        pageHeight: 0,
        pageWidth: 0
      }
    };

    this.windowResize = throttle(50, () => {
      this.orientation = getOrientation();
      this.updatePageSize();

      if (this.changedSincePreviousResize) {
        updatePageLayout(this);
      }

      if (this.ui) {
        this.ui.resize();
      }
    });

    if (isIE11) {
      document.body.classList.add('ie-11');
    }

    if (conf.errorMessage.length > 0) {
      showBackgroundError(containerElementID, conf.errorMessage);
    }

    const url = new URL(window.location.href);

    /**
     * @param {string} param
     * @returns {string}
     */
    const getQueryParam = param => url.searchParams.get(param) ?? '';

    // Custom JS Code may be expecting this function on the window.
    window.getQueryParamValue = param => url.searchParams.get(param);

    /**
     *
     * @param {string} param
     * @returns {string|null}
     */
    const getGuidQueryParam = param =>
      validateGUID(getQueryParam(param)).either(
        () => null,
        /** @param {string} x */
        x => x
      );

    /**
     * @param {string} token
     * @returns {boolean}
     */
    const validateProofingToken = token => {
      const proofingTokenRegex = /^[0-9a-f]{10,100}$/i;

      return proofingTokenRegex.test(token);
    };

    const matchesChar = curry(
      /**
       * @param {string} char
       * @param {string} value
       * @returns {boolean}
       */
      (char, value) => value.toLocaleLowerCase() === char.toLocaleLowerCase()
    );
    const isCharY = matchesChar('y');

    /**
     * @param {string} token
     * @returns {string}
     */
    const getValidatedProofingToken = token => (validateProofingToken(token) ? token : '');

    const magGUID = getGuidQueryParam('ptim');
    const versionGUID = getGuidQueryParam('ptii');
    const emailAddressFromUrl = getQueryParam('ptie');
    const emailFromCookie = Cookies.get('PTIE') ?? '';

    this.isTigerPlayer = Boolean(navigator.userAgent.match(/tigerplayer/gi));
    this.config = { ...window.ptiGetDefaultConfig(), ...conf };
    this.proofingToken = getValidatedProofingToken(getQueryParam('ptit'));
    this.previewMode = isCharY(getQueryParam('ptidp'));
    this.previewModeSinglePage = isCharY(getQueryParam('ptidpsp'));
    this.previewDesignerImages = isCharY(getQueryParam('ptidi'));
    this.disableMandatory = isCharY(getQueryParam('ptiddm'));
    // Page Template ID - Used for loading view with a specific page.
    this.pageTemplateId = getQueryParam('ptid');
    this.activeEvents = [];
    this.containerElementID = containerElementID;
    this.containerZoomElementID = `${containerElementID}_zoom`;
    this.elementID = `${containerElementID}_inner`;
    this.zoom = false;
    this.host = this.config.hostIncWWW;
    this.hostV2 = this.config.hostV2IncWWW;
    this.baseUrl = `https://${this.host}${window.ptiWS}`;
    this.firstPageAlreadyLoaded = false;
    this.gotoPageClickLinkPageNo = -1;
    this.gotoPageClickLinkLinkType = -1;
    this.gotoPageClickLinkLinkID = -1;
    this.checkOutstandingInteractivity = false;
    this.pages = [];
    this.onlineMagDefaultPopupBorderColour = this.config.defaultPopupBorderColour;
    this.onlineMagDefaultPopupButtonBGColour = this.config.defaultPopupButtonBGColour;
    this.onlineMagDefaultPopupButtonTextColour = this.config.defaultPopupButtonTextColour;

    if (magGUID && isGuid(magGUID)) {
      this.guid = magGUID;
    } else if (versionGUID && isGuid(versionGUID)) {
      this.guid = versionGUID;
    } else if (isGuid(documentGuid)) {
      this.guid = documentGuid;
    }

    const [validatedEmailAddress, shouldCreateEmailCookie] = getValidatedEmail(
      emailAddressFromUrl,
      emailFromCookie,
      this.config.emailAddress
    );

    this.emailAddress = validatedEmailAddress;

    if (this.emailAddress !== undefined && window.sendPtiEventData !== undefined) {
      window.sendPtiEventData(this.emailAddress, null, null, null);
    }

    if (shouldCreateEmailCookie) {
      this.createEmailCookie();
    }

    const $window = $(window);

    if (this.config.attractMode) {
      $window.on('mousemove', function mousemove() {
        window.ptiAttractModeLastInteraction = new Date();
      });

      $window.on('keydown', function keydown() {
        window.ptiAttractModeLastInteraction = new Date();
      });
    }

    this.loadDocument();
  }

  /** @returns {void} */
  createEmailCookie() {
    Cookies.set('PTIE', this.emailAddress, {
      expires: 365,
      path: this.config.emailCookiePath
    });
  }

  /**
   * @returns {Promise<string>}
   */
  getPageMarkup() {
    const { host } = this;
    const { accessibleInterface } = this.config;

    const url = this.pageTemplateId
      ? `https://${host}/api/v1/view/page/${this.pageTemplateId}?IGUID=${this.guid}&A=${accessibleInterface}&PT=${this.proofingToken}`
      : `https://${host}/api/v1/view/version?IGUID=${this.guid}&A=${accessibleInterface}&PT=${this.proofingToken}`;

    return fetch(url, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors'
    })
      .then(res => {
        if (res.ok) {
          return res;
        }

        return Promise.reject(new Error(getI18nByKey('Issues.Messages.ErrorLoadingContent')));
      })
      .then(res => res.text());
  }

  /**
   * @returns {void}
   */
  loadDocument() {
    const { disableGUI, errorMessage } = this.config;

    if (disableGUI) {
      $().ptibox.hideLoading();
      return;
    }

    if (errorMessage.length > 0) {
      showBackgroundError(this.containerElementID, errorMessage);

      return;
    }

    this.auth = auth.getAuth(this);

    if (auth.isAuthRequired(this.auth)) {
      delay(100).then(() => {
        this.auth.authFn();
      });
    } else {
      this.getDocumentData();
    }
  }

  /** @returns {void} */
  promptForEmailAndOrPassword() {
    const pageTurn = this;
    const { config, host } = pageTurn;
    const { captureEmail, coverThumbUrl, pwdRequired, rememberEmail } = config;
    const width = coverThumbUrl.length > 0 ? 600 : 400;
    let tempEmailForLogging = '';

    // @ts-ignore
    $().ptibox({
      cssClass: 'system-modal',
      borderColour: pageTurn.onlineMagDefaultPopupBorderColour,
      itemArray: [
        $.fn.ptibox.generateItemObject(
          this.getEmailPasswordMarkup(),
          '',
          '',
          'Login',
          null,
          width,
          460,
          null,
          false
        )
      ],
      showInfo: 2,
      showPlayButton: false,
      showCloseButton: false,
      hideOnEscape: false,
      hideOnOverlayClick: false,
      callbackOnShow() {
        /** @type {JQuery<HTMLFormElement>} */
        const $form = $('.js-capture-email-form');

        /** @type {JQuery<HTMLInputElement>} */
        const $emailAddressInput = $('#pti_txtEmailPassEmail');

        /** @type {JQuery<HTMLInputElement>} */
        const $rememberEmailInput = $('#pti_chkEmailPassRememberEmail');

        $form.find('input').get(0).focus();

        $form.off('submit').on('submit', function formSubmit(e) {
          e.preventDefault();

          $('.js-email-password-error').text('').removeClass('mod-show');

          if (captureEmail > 0) {
            const trimmedEmail = $emailAddressInput.val().toString().trim();
            pageTurn.emailAddress = trimmedEmail;
            tempEmailForLogging = trimmedEmail;

            if (rememberEmail > 0 && $rememberEmailInput.is(':checked')) {
              pageTurn.createEmailCookie();
            }
          }

          if (pwdRequired) {
            const password = $('#pti_txtEmailPassPass').val().toString();

            fetch(`https://${host}/api/v1/basiclogin/login`, {
              body: JSON.stringify({
                id: pageTurn.guid,
                password
              }),
              credentials: 'include',
              headers: {
                'Content-Type': 'application/json'
              },
              method: 'POST',
              mode: 'cors'
            })
              .then(async res => {
                if (res.ok) {
                  $form.off();
                  $().ptibox.close();
                  return Promise.resolve();
                }

                if (res.status === 400) {
                  return Promise.reject(await res.json());
                }

                if (res.status !== 200) {
                  return Promise.reject(new Error('Unexpected Server Response'));
                }
              })
              .catch(error => {
                const errorMessage =
                  error.message || error instanceof Error
                    ? error.message
                    : 'Unexpected Server Response';

                $('.js-email-password-error').text(errorMessage).addClass('mod-show');
              });
          } else {
            $form.off();
            $().ptibox.close();
          }
        });

        $form
          .find('input')
          .off('invalid')
          .on('invalid', function (e) {
            handleInvalidFormElement(e);
          });
      },
      callbackOnClose() {
        // It should be impossible to get into this flow...
        if (captureEmail === 2 && pageTurn.emailAddress.length === 0) {
          window.errorReporter.sendError({
            col: 0,
            line: 0,
            message: `PromptEmailPassword failure`,
            stack: `PromptEmailPassword failure: ${tempEmailForLogging}`,
            type: 'TypeError'
          });

          return;
        }

        pageTurn.getDocumentData();
      }
    });
  }

  /**
   * @returns {string}
   */
  getEmailPasswordMarkup() {
    const {
      captureEmail,
      defaultPopupButtonBGColour,
      defaultPopupButtonTextColour,
      loginText,
      coverThumbUrl,
      pwdRequired,
      rememberEmail
    } = this.config;
    const shouldShowRememberEmailAddress =
      captureEmail > 0 && rememberEmail > 0 && this.cookieFunctionalAccepted;
    const requiredAttr = captureEmail === 2 ? 'required' : '';
    const strChecked = rememberEmail === 2 ? 'checked="checked"' : '';

    const thumbNail = coverThumbUrl
      ? `<div class="email-verification-doc-image"><img src="${coverThumbUrl}" alt="${getI18nByKey(
          'Login.Images.Alt.FrontCover'
        )}"></div>`
      : '';
    const loginTextMarkup = loginText ? `<div class="pti_text">${loginText}</div>` : '';
    const captureEmailMarkup = captureEmail
      ? `<label for="pti_txtEmailPassEmail" class="pti_label">${getI18nByKey(
          'Login.Labels.EmailAddress'
        )}</label>
      <div class="pti_field">
        <input id="pti_txtEmailPassEmail" autocomplete="email" data-t="email-input" name="pti_txtEmailPassEmail" class="EmailPassEmail" ${requiredAttr} type="email" value="${htmlEncode(
          this.emailAddress
        )}" >
      </div>`
      : '';
    const requiredPassword = pwdRequired
      ? `
      <label for="pti_txtEmailPassPass" class="pti_label">${getI18nByKey(
        'Login.Labels.Password'
      )}</label>
      <div class="pti_field">
        <input id="pti_txtEmailPassPass" autocomplete="current-password" data-t="password-input" required minlength="1" name="pti_txtCompEmail" class="EmailPassPass popup-email-input" type="password" >
      </div>`
      : '';
    const privacyCheckbox = captureEmail
      ? `
      <div class="pti_privacy">
        <input id="pti_chkEmailPassPrivacy" data-t="privacy-checkbox" name="pti_chkEmailPassPrivacy" required class="EmailPassPrivacy" type="checkbox" >
        <label for="pti_chkEmailPassPrivacy">${getI18nByKey('Login.Labels.Accept')}</label>
          <a class="EmailPassPrivacyLink" href="https://www.pagetiger.com/cms/terms/privacypolicy" target="_blank">${getI18nByKey(
            'Login.Links.PrivacyNotice'
          )}</a>
      </div>`
      : '';
    const rememberEmailCheckbox = shouldShowRememberEmailAddress
      ? `
      <div class="pti_remember">
        <input id="pti_chkEmailPassRememberEmail" name="pti_chkEmailPassRememberEmail" class="EmailPassRememberEmail" type="checkbox" ${strChecked} >
        <label for="pti_chkEmailPassRememberEmail">${getI18nByKey(
          'Login.Labels.RememberEmailAddress'
        )}</label>
      </div>`
      : '';

    const headingText = () => {
      if (captureEmail && !requiredPassword) {
        return getI18nByKey('Login.Title.EmailAddressRequired');
      }

      return getI18nByKey('Login.Title.LoginRequired');
    };

    return `
      <div class="ptiemailpass">
        <div class="email-verification">
          ${thumbNail}
          <h1 class="visually-hidden">${headingText()}</h1>
          <form class="js-capture-email-form" data-t="capture-email-form">
            ${loginTextMarkup}
            <span class="form-error js-email-password-error" aria-live="polite"></span>
            ${captureEmailMarkup}
            ${requiredPassword}
            ${privacyCheckbox}
            ${rememberEmailCheckbox}
            <div style="display: flex; justify-content: center; margin-top: 20px;">
              <button class="form-element-button" data-t="submit" type="submit" style="background-color: ${defaultPopupButtonBGColour}; color: ${defaultPopupButtonTextColour}" type="submit">${getI18nByKey(
      'Login.Buttons.Submit'
    )}</button>
            </div>
          </form>
      </div>
    </div>`;
  }

  /**
   * @returns {void}
   */
  checkReaderLoggedIn() {
    var pageTurn = this;
    var { readerLoginVersion } = pageTurn.config;

    $.ajax({
      url: `${this.baseUrl}?M=91`,
      dataType: 'jsonp'
    }).then(function response(/** @param {ServerResponse} pobjReturn */ pobjReturn) {
      if (pobjReturn.returnStatus == 1) {
        if (pobjReturn.returnData && pobjReturn.returnData.length > 0) {
          const [emailAddress, remember] = pobjReturn.returnData.split(constants.COLUMN_SEPARATOR);

          pageTurn.emailAddress = emailAddress;

          if (remember === 'Y') {
            // Remember email
            pageTurn.createEmailCookie();
          }

          pageTurn.getDocumentData();
        } else {
          if (readerLoginVersion === 2) {
            document.location.href = `https://${
              pageTurn.host
            }/ReaderV2/LoginEmail/Default.aspx?redirecturl=${encodeURIComponent(
              document.location.href
            )}`;
          } else {
            document.location.href = `https://${
              pageTurn.host
            }/Reader/Login/Default.aspx?redirecturl=${encodeURIComponent(document.location.href)}`;
          }
        }
      } else if (pobjReturn.returnStatus == 2 && pobjReturn.returnMessage.length > 0) {
        alert(pobjReturn.returnMessage);
      } else {
        alert('Unable to check that reader has logged in');
      }
    });
  }

  /**
   * @returns {void}
   */
  checkEmployeeLoggedIn() {
    const pageTurn = this;
    const { baseUrl } = this;

    $.ajax({
      url: `${baseUrl}?M=92`,
      dataType: 'jsonp'
    }).then(function response(/** @param {ServerResponse} pobjReturn */ pobjReturn) {
      if (pobjReturn.returnStatus == 1) {
        if (pobjReturn.returnData && pobjReturn.returnData.length > 0) {
          const [emailAddress, remember] = pobjReturn.returnData.split(constants.COLUMN_SEPARATOR);

          pageTurn.emailAddress = emailAddress;

          if (remember === 'Y') {
            // Remember email
            pageTurn.createEmailCookie();
          }

          // All good, load publication
          pageTurn.getDocumentData();
        } else {
          document.location.href = `https://${
            pageTurn.host
          }/EmployeeNo/Login/Default.aspx?redirecturl=${encodeURIComponent(
            document.location.href
          )}`;
        }
      } else if (pobjReturn.returnStatus == 2 && pobjReturn.returnMessage.length > 0) {
        alert(pobjReturn.returnMessage);
      } else {
        alert('Unable to check that employee has logged in');
      }
    });
  }

  /**
   * @returns {void}
   */
  checkSsoLoggedIn() {
    const pageTurn = this;
    const { host } = pageTurn;
    const url = `${this.baseUrl}?M=93&IGUID=${this.guid}`;

    $.ajax({
      url,
      dataType: 'jsonp'
    })
      .then(function response(pobjReturn) {
        if (pobjReturn.returnStatus == 1) {
          if (pobjReturn.returnData && pobjReturn.returnData.length > 0) {
            pageTurn.emailAddress = pobjReturn.returnData;

            pageTurn.getDocumentData();
          } else {
            document.location.href = `https://${host}/sso/Default.aspx?redirecturl=${encodeURIComponent(
              document.location.href
            )}`;
          }
        } else if (pobjReturn.returnStatus == 2 && pobjReturn.returnMessage.length > 0) {
          // eslint-disable-next-line
          alert(pobjReturn.returnMessage);
        } else {
          // eslint-disable-next-line
          alert('Unable to check that user logged in');
        }
      })
      .catch(console.error);
  }

  /**
   * @returns {void}
   */
  finishVisit() {
    const {
      host,
      documentName,
      versionName,
      onlineMagIssueDeviceID,
      onlineMagIssueID,
      onlineMagIssueVisitID
    } = this;

    if (onlineMagIssueVisitID) {
      const url = `//${host}/${documentName}/${versionName}/EndVisit.htm?VID=${onlineMagIssueVisitID}&IID=${onlineMagIssueID}&DID=${onlineMagIssueDeviceID}`;

      if ('sendBeacon' in navigator) {
        sendBeacon(url);
      } else {
        // Sync call to issue hosting domain to log end of visit
        $.ajax({
          async: false,
          type: 'GET',
          url
        });
      }
    }
  }

  /**
   *
   * @param {string} googleSheetID
   * @param {(data: any) => void} callback
   */
  getGoogleSheet(googleSheetID, callback) {
    const url = `${this.baseUrl}?M=200&VID=${this.onlineMagIssueVisitID}&IID=${
      this.onlineMagIssueID
    }&SID=${encodeURI(googleSheetID)}`;

    $.ajax({
      url,
      dataType: 'jsonp'
    }).then(function response(returnVal) {
      if (returnVal.returnStatus == 1) {
        if (returnVal.returnData && returnVal.returnData.length > 0) {
          callback(returnVal.returnData);
        }
      }
    });
  }

  /**
   * @returns {string}
   */
  getDocumentUrl() {
    const { emailMessageGuid, referralUrl } = this.config;
    const DP = this.previewMode ? 'Y' : '';
    const DPSP = this.previewModeSinglePage ? 'Y' : '';
    const DI = this.previewDesignerImages ? 'Y' : '';
    const pageNumber = '0';
    const url = new URL(this.baseUrl);

    url.searchParams.set('M', '1');
    url.searchParams.set('IGUID', this.guid);
    url.searchParams.set('PN', pageNumber);
    url.searchParams.set('DP', DP);
    url.searchParams.set('DPSP', DPSP);
    url.searchParams.set('DI', DI);
    url.searchParams.set('PT', this.proofingToken);
    url.searchParams.set('EA', this.emailAddress);
    url.searchParams.set('EMG', emailMessageGuid ?? '');
    url.searchParams.set('HN', document.location.hostname);
    url.searchParams.set('RU', referralUrl);

    if (this.pageTemplateId) {
      url.searchParams.set('PTID', this.pageTemplateId);
    }

    return url.href;
  }

  /** @returns {Promise<ServerResponse>} */
  getOliSON() {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: this.getDocumentUrl(),
        dataType: 'jsonp',
        success: resolve,
        error: reject
      });
    });
  }

  getDocumentData() {
    const requiresPageMarkup =
      this.config.accessibleInterface === 'H' || this.config.accessibleInterface === 'C';
    const pageMarkUpPromise = requiresPageMarkup ? this.getPageMarkup() : Promise.resolve('');

    Promise.all([this.getOliSON(), pageMarkUpPromise])
      .then(([oliSON, pageMarkup]) => {
        this.gotDocumentData(oliSON, pageMarkup);
      })
      .catch(
        /** @param {Error} error */ error => {
          showBackgroundError(this.containerElementID, getBackgroundErrorMessage(error.message));

          window.errorReporter.sendError({
            col: 0,
            line: 0,
            message: `ERROR LOADING DOCUMENT: ${error.message}`,
            stack: error.stack,
            type: 'Error'
          });
        }
      );
  }

  /** eslint-disable max-lines-per-function, prefer-destructuring */
  /**
   * @this {OnlineMag}
   * @param {ServerResponse} pobjReturn
   * @param {string} pageMarkup
   * @returns {void}
   */
  gotDocumentData(pobjReturn, pageMarkup) {
    /** @type {OnlineMag} */

    const objOnlineMagIssue = this;
    const body = document.body;
    const $body = $(body);
    const $head = $('head');
    var htmlMarkup = '';
    var elementMarkup = '';

    if (pobjReturn.returnStatus !== '1') {
      return;
    }

    const authPathSection = auth.getAuthPath(this.auth);
    const hasAuthRedirect = Boolean(authPathSection);

    const authPath = `https://${this.host}/${authPathSection}/`;

    const authPathV2 = this.config.features.myAccountV2 ? `https://${this.hostV2}/` : undefined;

    if (hasAuthRedirect) {
      auth.authCheck(this);
      document.addEventListener('visibilitychange', auth.handleVisibilityChange(this), false);
    }

    const docData = pobjReturn.returnData.split(constants.TABLE_SEPARATOR);
    const general = defaultToEmptyString(docData[0]);
    const previousSession = defaultToEmptyString(docData[1]);
    const documentInfo = defaultToEmptyString(docData[2]);
    this.onlineMagShowIssueList = isTrue(docData[3]);
    const issueData = defaultToEmptyString(docData[4]);
    const documentPages = defaultToEmptyString(docData[5]);
    const documentLinks = defaultToEmptyString(docData[6]);
    const documentPollAnswers = defaultToEmptyString(docData[7]);
    const documentArticles = defaultToEmptyString(docData[8]);
    const documentIframes = defaultToEmptyString(docData[9]);
    const documentVideos = defaultToEmptyString(docData[10]);
    const documentSubtitles = defaultToEmptyString(docData[11]);
    const documentJumps = defaultToEmptyString(docData[12]);
    const documentComps = defaultToEmptyString(docData[13]);
    const documentGalleries = defaultToEmptyString(docData[14]);
    const documentGalleryPhotos = defaultToEmptyString(docData[15]);
    const documentForwards = defaultToEmptyString(docData[16]);
    const documentShares = defaultToEmptyString(docData[17]);

    // Check for notifications, and display them if necessary
    notifications.getNotifications(this);

    /**
     * When loading view from designer with limited pages, we need to check if
     * we're not on a lead page and force `alwaysOpened` to true.
     */
    const isFirstPageACover =
      documentPages
        .split(constants.ROW_SEPARATOR)
        ?.at(0)
        ?.split(constants.COLUMN_SEPARATOR)
        ?.at(-1) ?? 'false';

    if (this.pageTemplateId && this.previewMode && !isTrue(isFirstPageACover)) {
      this.config.alwaysOpened = true;
    }

    /** @type {import('./@types/charts').ChartModule[]} */
    const charts = parseJson(docData[18]).either(
      () => [],
      data =>
        data.charts.map(chart => {
          chart.data.defaultOptions = chart.data.options;
          chart.data.smallOptions = getCustomChartSizes(chart.data.options, 12, 2);
          chart.data.xsmallOptions = getCustomChartSizes(chart.data.options, 8, 4);
          chart.data.xxSmallOptions = getCustomChartSizes(chart.data.options, 3, 8);

          return chart;
        })
    );

    /** @type {ToolbarMenuItem} */
    const cookieMenuItem = {
      type: 'button',
      action: 'ptiCookie',
      extraClasses: '',
      text: getI18nByKey('Menu.Cookies'),
      validate: _ => true
    };

    const arGeneral = general.split(constants.COLUMN_SEPARATOR);

    this.onlineMagIssueVisitID = parseInt(arGeneral[0]);
    this.onlineMagIssueDeviceID = parseInt(arGeneral[1]);
    const mainWebsiteDomainIncWWW = arGeneral[2];
    const tokenTradingName = arGeneral[3];
    const errorMessage = arGeneral[4];
    const lastVisitPageIndex = parseInt(arGeneral[5]);

    if (errorMessage.length > 0 || documentInfo.length === 0) {
      showBackgroundError(this.containerElementID, errorMessage);

      $.fn.ptibox.hideLoading();

      return;
    }

    this.previousSession =
      previousSession.length > 0 ? previousSession.split(constants.ROW_SEPARATOR) : [];

    var cookiePreferencesPositionClass = '';

    const arOnlineMag = documentInfo.split(constants.COLUMN_SEPARATOR);

    this.onlineMagStackableGap = false;
    this.attractModeActive = false;
    const onlineMagID = parseInt(arOnlineMag[0]);
    this.documentName = arOnlineMag[3];
    this.onlineMagWidth = parseInt(arOnlineMag[4]);
    this.onlineMagHeight = parseInt(arOnlineMag[5]);
    this.onlineMagFrameHeight = parseInt(arOnlineMag[7]);
    const onlineMagMenuSubmenuArrows = isTrue(arOnlineMag[14]);
    const passThroughParams = isTrue(arOnlineMag[41]);
    this.heightScale = parseFloat(arOnlineMag[51]);

    /* eslint-disable-next-line */
    var docSettings = parseDocumentSettings(arOnlineMag[55]);

    this.availableLayouts = docSettings.availableLayouts;
    this.onlineMagDefaultLayoutPC = docSettings.defaultLayoutPC;
    this.onlineMagDefaultLayoutMobile = docSettings.defaultLayoutMobile;
    this.onlineMagDefaultPopupBorderColour = docSettings.popupBorderColour;
    this.onlineMagDefaultPopupButtonBGColour = docSettings.popupButtonBGColour;
    this.onlineMagDefaultPopupButtonTextColour = docSettings.popupButtonTextColour;
    this.onlineMagFrameWidth = docSettings.frameWidth;
    this.onlineMagFrameLeftRightMargin = docSettings.frameLeftRightMargin;
    this.onlineMagFrameTopBottomMargin = docSettings.frameTopBottomMargin;
    this.onlineMagFrameStretchToFit = docSettings.frameStretchToFit;
    this.onlineMagFrameStretchToFitSingle = docSettings.frameStretchToFitSingle;
    this.toolbarColour = docSettings.frameToolbarColour;
    this.toolbarIconColour = docSettings.frameToolbarIconColour;
    const onlineMagFrameToolbarPosition = docSettings.frameToolbarPosition;
    const toolbarSize = docSettings.frameToolbarSize;
    const toolbarLogoUrl = rewriteToUseSSL(docSettings.frameToolbarLogoAbsoluteUrl);
    const toolbarLogoLink = docSettings.frameToolbarLogoLink.toLowerCase();
    const onlineMagShowLogo = docSettings.showLogo;
    this.onlineMagShowSearch = docSettings.showSearch;
    this.onlineMagShowDownload = docSettings.showDownload;
    this.onlineMagShowPrint = docSettings.showPrint;
    this.onlineMagShowShare = docSettings.showShare;
    this.onlineMagShowPaging = docSettings.showPaging;
    this.onlineMagShowIssueList = docSettings.showIssueList;
    this.onlineMagShowContents = docSettings.showContents;
    this.onlineMagClickableAreaStyle = docSettings.clickableAreaStyle;
    this.onlineMagClickableAreaColour = docSettings.clickableAreaColour;
    this.onlineMagSideNavColour = docSettings.sideNavColour;
    this.onlineMagSideNavUseToolbarColour = docSettings.sideNavUseToolbarColour;
    this.onlineMagSideNavIconColour = docSettings.sideNavIconColour;
    this.onlineMagSideNavUseToolbarIconColour = docSettings.sideNavUseToolbarIconColour;
    this.onlineMagMandatoryClickableAreaColour = docSettings.mandatoryClickableAreaColour;
    this.onlineMagSideNavBehaviour = docSettings.sideNavBehaviour;
    this.enableCornerFolds = docSettings.allowManualPageTurn;
    const reduceMotion = prefersReducedMotion();
    const zoomEnabled = safeNumberFromString(docSettings.zoomMagnification).option(0) > 0;
    this.pageShadows = getPageShadow(docSettings.showPageShadows, reduceMotion);
    this.pageTurnSpeed = reduceMotion ? 0 : safeNumberFromString(docSettings.moveSpeed).option(800);
    this.documentAnalytics = parseAnalytics(arOnlineMag[56]);
    this.cookieMessage = htmlEncode(defaultToEmptyString(arOnlineMag[57]));
    this.cookieManager = getCookieManager(arOnlineMag[58]);

    const menuHtml = arOnlineMag[12];
    const menuJsCss = arOnlineMag[13];
    const menuJson = arOnlineMag[59];
    const forceLegacyMenu = isTrue(arOnlineMag[60]) || isIE11;
    const isToolbarHidden = onlineMagFrameToolbarPosition === TOOLBAR_POSITION.HIDDEN;
    const showLogoInMenu = onlineMagShowLogo && isToolbarHidden && menuHtml.length > 0;
    const showAccountInMenu = hasAuthRedirect && isToolbarHidden && menuHtml.length > 0;
    const accountMenu = menu.getMenuAccount(
      hasAuthRedirect,
      authPath,
      authPathV2,
      onlineMagID,
      showAccountInMenu
    );

    this.menu = menu.getMenuVersion({
      menuHtml,
      menuJsCss,
      menuJson,
      showLogo: showLogoInMenu,
      accountMenu,
      forceLegacyMenu
    });

    this.cookieManager.setup(this);

    const pageTigerCookiePopupShouldBeShown =
      this.cookieManager.brand === COOKIE_MANAGERS.PAGETIGER &&
      getCookiePreferences() === '' &&
      !this.previewDesignerImages &&
      !this.isTigerPlayer;

    const url = new URL(window.location.href);

    /**
     * @param {string} param
     * @returns {string}
     */
    const getQueryParam = param => url.searchParams.get(param) ?? '';
    const passThroughName = getQueryParam('ptix');
    const passThroughValue = getQueryParam(passThroughName);
    const passThroughParamName = passThroughName ? encodeURIComponent(passThroughName) : '';
    const passThroughParamValue =
      passThroughName && passThroughValue ? encodeURIComponent(passThroughValue) : '';

    /* If we're being previewed from within designer,
     then we want to force the book layout */
    if (this.previewMode && this.previewDesignerImages) {
      docSettings.pageAnimationMobile = constants.ANIMATION_MODES.BOOK;
      docSettings.pageAnimationPC = constants.ANIMATION_MODES.BOOK;
    }

    const viewingDetails = getViewInterface(
      docSettings,
      this.config.accessibleInterface,
      document.documentElement.clientWidth
    );

    this.viewingInterface = viewingDetails.mode;
    cookiePreferencesPositionClass = 'mod-fixed';

    if (viewingDetails.mode === STACKABLE || viewingDetails.mode === HTML_STACKABLE) {
      this.onlineMagSideNavBehaviour = 'hidden';
      this.onlineMagShowPaging = false;
      this.config.scrollTopOnPaging = false;
      body.classList.add('mod-stackable');
    }

    // $head.append(`
    //   <style>
    //     ${get(this, docSettings)}
    //   </style>
    // `);

    $(window).on('unload', this.finishVisit);

    this.orientation = getOrientation();
    this.suppressChainOnPageShow = false;
    this.isSmallScreen = document.documentElement.clientWidth < constants.SMALL_SCREEN_WIDTH;
    /** @type {RemoteData.RemoteData<string, IssueList>} */
    this.issueList = RemoteData.NotAsked();

    const issueDataArr = issueData.split(constants.COLUMN_SEPARATOR);
    this.onlineMagIssueID = parseInt(issueDataArr[0]);
    this.onlineMagIssueUrl = rewriteToUseSSL(issueDataArr[1]);
    this.versionName = issueDataArr[2];
    const thumbnailSize = parseInt(issueDataArr[3]);

    // Workout the thumb width and height
    if (this.onlineMagHeight > this.onlineMagWidth) {
      // Portrait
      this.thumbnailHeight = thumbnailSize;

      this.thumbnailWidth = Math.round(
        this.onlineMagWidth / (this.onlineMagHeight / thumbnailSize)
      );
    } else {
      // Landscape
      this.thumbnailWidth = thumbnailSize;

      this.thumbnailHeight = Math.round(
        this.onlineMagHeight / (this.onlineMagWidth / thumbnailSize)
      );
    }

    var pagePanels = [[]];
    if (pageMarkup && !isIE11) {
      const [fonts, pageContent] = parseHtmlString(pageMarkup);

      /** @type {NodeListOf<HTMLButtonElement | HTMLAnchorElement>} */
      const mandatoryElements = pageContent.querySelectorAll("[data-mandatory='true']");

      /* When rendering the templates on the server we don't have access to the previousSession
      information (largely due to caching). Therefore we add the mandatory class client-side
      to ensure that elements have the correct mandatory styling. Polls need to look for the Poll
      module ID not the indiviual poll answer ID */
      mandatoryElements.forEach(el => {
        if (
          !(
            this.previousSession.includes(`${el.dataset.moduleType}-${el.dataset.id}`) ||
            this.previousSession.includes(`${el.dataset.moduleType}-${el.dataset.pollid}`)
          )
        ) {
          el.classList.add(MANDATORY_CLASS);
        }
      });

      outputFontsStyles(fonts.textContent);

      pagePanels = Array.from(pageContent.children).map(p => Array.from(p.children));
    }

    /** @type {OnlineMagPageLink[]} */
    this.allOnlineMagPageLinks = [];
    /** @type {OnlineMagPagePollAnswers[]} */
    this.allOnlineMagPagePollAnswers = [];
    /** @type {OnlineMagPageArticle[]} */
    this.allOnlineMagPageArticles = [];
    /** @type {OnlineMagPageIFrame[]} */
    this.allOnlineMagPageIFrames = [];
    /** @type {OnlineMagPageVideo[]} */
    this.allOnlineMagPageVideos = [];
    this.allOnlineMagPageVideoSubtitles = [];
    /** @type {OnlineMagPageJump[]} */
    this.allOnlineMagPageJumps = [];
    /** @type {Competition[]} */
    this.allOnlineMagPageComps = [];
    /** @type {OnlineMagPageGallery[]} */
    this.allOnlineMagPageGalleries = [];
    /** @type {OnlineMagPageForward[]} */
    this.allOnlineMagPageForwards = [];
    /** @type {OnlineMagPageShare[]} */
    this.allOnlineMagPageShares = [];
    /** @type {OnlineMagPageChart[]} */
    this.allOnlineMagPageCharts = [];

    this.onlineMagPages = new OnlineMagPages(
      this,
      documentPages,
      documentLinks,
      documentPollAnswers,
      documentArticles,
      documentIframes,
      documentVideos,
      documentSubtitles,
      documentJumps,
      documentComps,
      documentGalleries,
      documentGalleryPhotos,
      documentForwards,
      documentShares,
      charts,
      pagePanels
    );

    var modules = [
      ...this.allOnlineMagPageLinks,
      ...this.allOnlineMagPagePollAnswers,
      ...this.allOnlineMagPageArticles,
      ...this.allOnlineMagPageIFrames,
      ...this.allOnlineMagPageVideos,
      ...this.allOnlineMagPageJumps,
      ...this.allOnlineMagPageComps,
      ...this.allOnlineMagPageGalleries,
      ...this.allOnlineMagPageForwards,
      ...this.allOnlineMagPageShares,
      ...this.allOnlineMagPageCharts
    ].filter(
      m =>
        m.focusOutlineColour !== '' ||
        (m.moduleType === MODULES.VIDEO && m.subType !== VIDEO_SUB_TYPES.MP4_IN_PAGE) ||
        (m.moduleType === MODULES.CHART && m.subType !== MODULE_SUB_TYPES.CHART)
    );

    $head.append(
      `<style>
          ${getToolbarStyles(this, docSettings)}
          ${getInteractivityStyles(this, modules)}
        </style>`
    );

    setupDesignerMessaging(this);

    const layout = getLayout(this);

    // Load complete, allow further chance for config to be overridden
    if (typeof window.ptiOverrideConfigPostLoad === 'function') {
      window.ptiOverrideConfigPostLoad(this.config);
    }

    /** @type {ToolbarItems} */
    const toolbarLeft = [];
    /** @type {ToolbarItems} */
    let toolbarCenter = [];

    const showLogoInTopToolBar =
      onlineMagShowLogo && onlineMagFrameToolbarPosition === 'top' && !showLogoInMenu;

    const showLogoInBottomToolBar =
      onlineMagShowLogo &&
      onlineMagFrameToolbarPosition.includes('bottom') &&
      !showLogoInMenu &&
      !showLogoInTopToolBar;

    const showAccountInTopToolbar =
      hasAuthRedirect && onlineMagFrameToolbarPosition === 'top' && !showAccountInMenu;

    const showAccountInBottomToolbar =
      hasAuthRedirect &&
      onlineMagFrameToolbarPosition.includes('bottom') &&
      !showAccountInMenu &&
      !showAccountInTopToolbar;

    window.ptiHookupLeftRightArrows(this.containerElementID);

    const userAgentLower = userAgent.toLowerCase();
    const isMobileOrTablet =
      userAgentLower.includes('tablet') ||
      userAgentLower.includes('mobile') ||
      userAgentLower.includes('android');

    const imageModeStyles = isJpgView(this.viewingInterface)
      ? `position: relative; margin: ${this.onlineMagFrameTopBottomMargin}px 0; `
      : '';

    elementMarkup = `
    <main id="main" data-t="zoom" class="container-zoom mod-flex" style="" tabIndex="-1">
      <div id="${this.containerZoomElementID}" style="overflow: hidden; ${imageModeStyles}">
        ${this.elementMarkup()}
      </div>
    </main>`;

    const isPageTurnInterface = this.viewingInterface === PAGE_TURN;

    // Only enable zoom if the document fits nicely within the viewport
    if (
      this.onlineMagFrameStretchToFit &&
      !isMobileOrTablet &&
      zoomEnabled &&
      isPageTurnInterface
    ) {
      elementMarkup = `<pinch-touch class="js-zoom" single-page-mode="${this.onlineMagFrameStretchToFitSingle}">${elementMarkup}</pinch-touch>`;
    }

    $body.append(`
      <div role="region" aria-live="polite">
        <span class="visually-hidden ${GLOBAL_ARIA_LIVE_CLASS}"></span>
      </div>
    `);

    htmlMarkup = '';

    const isStandardToolbar = toolbarSize === 'Standard';
    const standardNavClass = isStandardToolbar ? 'ptinav_standard' : '';

    if (isStandardToolbar) {
      if (toolbarLogoUrl) {
        const currentUrl = new URL(window.location.href);
        const image = `<img src="${toolbarLogoUrl}" alt="${getI18nByKey(
          'Toolbar.CustomerLogo.Image.Alt'
        )}">`;

        if (toolbarLogoLink.length > 0) {
          const logoUrl = parseUrl(toolbarLogoLink).map(url => {
            if (passThroughParams) {
              for (const [key, value] of currentUrl.searchParams.entries()) {
                url.searchParams.set(key, value);
              }
            } else if (passThroughParamName) {
              url.searchParams.set(passThroughParamName, passThroughParamValue);
            }

            return url.href;
          });

          const linkAttrs =
            docSettings.frameToolbarLogoTarget === '_blank'
              ? 'target="_blank" rel="noopener noreferrer"'
              : '';

          const imageMarkUp = either(
            () => image,
            /** @param {string} url */ url => `
            <a class="toolbar-customer-logo-link" href="${url}" ${linkAttrs}>
              ${image}
              <span class="visually-hidden">${getI18nByKey('Toolbar.CustomerLogo.Link.Text')}</span>
            </a>`
          );

          toolbarLeft.push({
            type: 'markup',
            markup: `<div class="hide-on-small-screen toolbar-customer-logo">${imageMarkUp(
              logoUrl
            )}</div>`
          });
        } else {
          toolbarLeft.push({
            type: 'markup',
            markup: `<div class="hide-on-small-screen toolbar-customer-logo">${image}</div>`
          });
        }
      }
    }

    const toolbarMenu = getToolbarItems(this);

    if (toolbarMenu.length > 0) {
      toolbarLeft.push({
        type: 'menu',
        buttonMarkup: `
        <button class="toolbar-menu-button js-toggle-toolbar-popup" data-t="toolbar-menu-button" aria-haspopup="true" aria-expanded="false">
          <span class="no-pointer visually-hidden">${getI18nByKey(
            'Toolbar.Buttons.ToolbarItems'
          )}</span>
          <svg class="mobile-menu-button-svg no-pointer">
            <use xlink:href="#svg-menu"></use>
          </svg>
        </button>`,
        // @ts-ignore
        dropdownOptions: toolbarMenu,
        extraClasses: 'mod-top'
      });
    }

    const centralMarkup =
      this.onlineMagPages.items.length === 1
        ? `<div class="toolbar-button" style="display: flex; width: auto;">
          <span class="visually-hidden">${getI18nByKey('Toolbar.Pagination.Page')} </span>
          <span class="toolbar-menu-button-current-page js-current-page no-pointer" style="display: flex; align-items: center;" data-t="toolbar-paging-info"></span>
          </div>`
        : `<button class="toolbar-button mod-width-auto ptinav_icon ptinav_page_editable" data-t="page-navigation-button" type="button" data-action="ptiEnterPageNumber" data-tooltip="${getI18nByKey(
            'Toolbar.Pagination.ClickToEnter'
          )}">
      <span class="visually-hidden">${getI18nByKey('Toolbar.Pagination.Page')}  </span>
      <span class="toolbar-menu-button-current-page js-current-page no-pointer" data-t="toolbar-paging-info"></span>
    </button>`;

    /** @type {ToolbarItems} */
    const centralNavigation = [
      {
        type: 'button',
        action: 'ptiPreviousPage',
        extraClasses: `${constants.NAV_ARROW_CLASS} mod-prev`,
        svgID: 'arrow',
        text: getI18nByKey('Buttons.PreviousPage')
      },
      {
        type: 'markup',
        markup: centralMarkup
      },
      {
        type: 'button',
        action: 'ptiNextPage',
        extraClasses: `${constants.NAV_ARROW_CLASS} mod-next`,
        svgID: 'arrow',
        text: getI18nByKey('Buttons.NextPage')
      }
    ];

    if (this.onlineMagShowPaging) {
      toolbarCenter = centralNavigation;
    }

    /** @type {ToolbarMenuItem[]} */
    const accountDropdown = [];
    let myAccount = '';
    const currentUrl = encodeURIComponent(window.location.href);

    const myAccountUrl = this?.config?.features?.myAccountV2
      ? `${authPathV2}?redirecturl=${currentUrl}`
      : `${authPath}MyAccount/Default.aspx?MID=${onlineMagID}&redirecturl=${currentUrl}&TI=0`;

    if (hasAuthRedirect) {
      accountDropdown.push({
        type: 'link',
        extraClasses: 'my-account-link',
        action: myAccountUrl,
        text: 'My Account'
      });
      accountDropdown.push({
        type: 'link',
        extraClasses: '',
        action: `${authPath}Logout/Default.aspx?MID=${onlineMagID}&redirecturl=${currentUrl}`,
        text: 'Logout'
      });

      myAccount = renderToolbarMenu({
        buttonMarkup: `
        <button id="my-account" class="toolbar-menu-button js-toggle-toolbar-popup" aria-haspopup="true" aria-expanded="false" data-t="my-account" aria-label="${getI18nByKey(
          'Toolbar.Buttons.MyAccount'
        )}">
          <svg class="mobile-menu-button-svg no-pointer" role="presentation">
            <use xlink:href="#svg-account"></use>
          </svg>
        </button>`,
        dropdownOptions: [
          {
            type: 'link',
            extraClasses: 'my-account-link',
            action: myAccountUrl,
            text: getI18nByKey('Toolbar.Buttons.MyAccount')
          },
          {
            type: 'link',
            extraClasses: '',
            action: `${authPath}Logout/Default.aspx?MID=${onlineMagID}&redirecturl=${currentUrl}`,
            text: getI18nByKey('Toolbar.Buttons.Logout')
          }
        ],
        extraClasses: 'mod-account'
      });
    }

    const pageTigerLogo = renderToolbarLink({
      type: 'link',
      extraClasses: 'mod-logo',
      href: `https://${mainWebsiteDomainIncWWW}`,
      newWindow: true,
      svgID: 'logo',
      text: getI18nByKey('Branding.PoweredByPageTiger')
    });

    const stickyClass = '';

    htmlMarkup = `${htmlMarkup}<div class="top-nav-items js-nav-bar ${stickyClass} ${standardNavClass}" data-t="toolbar-top">`;

    if (menu.isLegacy(this.menu)) {
      htmlMarkup = `${htmlMarkup}<div class="js-responsive-menu-mount"></div>`;
      htmlMarkup = htmlMarkup + this.menu.menuHtml;

      if (this.menu.menuJsCss.length > 0) {
        $head.append(this.menu.menuJsCss);
      }
    }

    htmlMarkup = `${htmlMarkup}<div class="${GLOBAL_MENU_MOUNTING_POINT_CLASS}"></div>`;

    if (onlineMagFrameToolbarPosition.includes('top')) {
      htmlMarkup =
        htmlMarkup +
        renderToolBar({
          accountPopup: showAccountInTopToolbar ? myAccount : '',
          navClass: standardNavClass,
          pageTigerLogo: showLogoInTopToolBar ? pageTigerLogo : '',
          position: 'top',
          toolbarCenter,
          toolbarLeft,
          toolbarPlacement: 'bottom'
        });
    }

    htmlMarkup = `${htmlMarkup}</div>`;

    htmlMarkup = htmlMarkup + elementMarkup;

    const cookieFixedStyle = !isPageTurnInterface ? `position: fixed;` : ``;

    if (pageTigerCookiePopupShouldBeShown) {
      htmlMarkup = `${htmlMarkup}
      <div class="js-popup-cookie-notice cookie-notice" data-t="cookie-banner" tabindex="-1" style="${cookieFixedStyle}">
        <form class="cookie-simple-form js-cookie-form">
          <div style="max-width: 100%;">
            <h2 class="cookie-notice-header">${getI18nByKey('Cookies.Title')}</h2>
            <p class="cookie-notice-text">${this.cookieMessage}</p>
          </div>

          <div class="cookie-notice-button-container">
            <button class="cookie-notice-button" onclick="this.form.submitted=this.value;" value="accept" type="submit" data-t="cookie-accept-btn" aria-label="Accept all cookies">${getI18nByKey(
              'Cookies.AcceptAll'
            )}</button>
            <button class="cookie-notice-button" onclick="this.form.submitted=this.value;" value="reject" type="submit" data-t="cookie-reject-btn" aria-label="Reject all cookies">${getI18nByKey(
              'Cookies.RejectAll'
            )}</button>
            <button class="cookie-notice-button mod-secondary" onclick="javascript: window.ptiCookie();" type="button" data-t="cookie-custom-settings" aria-label="Customise cookies">${getI18nByKey(
              'Cookies.Customise'
            )}</button>
          </div>
        </form>
      </div>`;
    }

    /**
     * Add toaster mounting div into the bottom toolbar, but only if we're not in preview mode.
     */
    if (!this.previewMode) {
      htmlMarkup = `${htmlMarkup}
        <div id="notifications-mounting-point">
          <div id="notifications"></div>
        </div>`;
    }

    htmlMarkup = `${htmlMarkup}
  <div class="bottom-nav-items js-nav-bar ${stickyClass} ${standardNavClass}" data-t="toolbar-bottom">`;

    if (onlineMagFrameToolbarPosition.includes('bottom')) {
      htmlMarkup =
        htmlMarkup +
        renderToolBar({
          accountPopup: showAccountInBottomToolbar ? myAccount : '',
          navClass: standardNavClass,
          pageTigerLogo: showLogoInBottomToolBar ? pageTigerLogo : '',
          position: 'bottom',
          toolbarCenter,
          toolbarLeft,
          toolbarPlacement: 'top'
        });
    }

    /**
     * Renders the normal toolbar menu (visible on narrow viewports, i.e. mobile)
     */
    if (
      (isToolbarHidden && !this.isTigerPlayer && this.onlineMagPages.length > 1) ||
      (isToolbarHidden && !this.previewMode && !this.isTigerPlayer)
    ) {
      const hiddenClass = this.onlineMagShowPaging ? '' : 'mod-hidden-on-mobile';

      htmlMarkup =
        htmlMarkup +
        renderToolBar({
          accountPopup: '',
          navClass: `${standardNavClass} mod-mobile ${hiddenClass}`,
          pageTigerLogo: '',
          position: 'bottom',
          toolbarCenter: centralNavigation,
          toolbarLeft,
          toolbarPlacement: 'top'
        });
    }

    htmlMarkup = `${htmlMarkup}</div>`;

    $(`#${this.containerElementID}`).html(htmlMarkup);

    /**
     * "Hidden" toolbar menu - visible on wider screens as a floating action button.
     */
    if (isToolbarHidden && !this.previewMode && !this.isTigerPlayer) {
      $body.append(
        `<div data-position="bottom" class="cookie-preference ${cookiePreferencesPositionClass}" style="background-color: ${
          this.toolbarColour
        };">
        ${renderToolbarMenu({
          buttonMarkup: `
            <button class="toolbar-menu-button js-toggle-toolbar-popup" data-t="toolbar-menu-button" aria-haspopup="true" aria-expanded="false">
              <span class="no-pointer visually-hidden">${getI18nByKey(
                'Toolbar.Buttons.ToolbarItems'
              )}</span>
              <svg class="mobile-menu-button-svg no-pointer">
                <use xlink:href="#svg-menu"></use>
              </svg>
            </button>`,
          // @ts-ignore
          dropdownOptions: toolbarMenu,
          extraClasses: 'mod-bottom'
        })}
        </div>`
      );
    }

    const $desktopMenuRight = $('.js-desktop-menu-right');

    if (menu.isLegacy(this.menu)) {
      responsiveMenu(
        mainWebsiteDomainIncWWW,
        showLogoInMenu,
        showAccountInMenu ? accountDropdown : []
      );

      $('.ptiMenuBar').superfish({
        cssArrows: onlineMagMenuSubmenuArrows,
        delay: 500,
        speed: 'fast'
      });

      if (this.menu.accountMenu) {
        $desktopMenuRight.append(
          renderToolbarMenu({
            buttonMarkup: `
          <button class="toolbar-menu-button js-toggle-toolbar-popup" aria-haspopup="true" aria-expanded="false" aria-label="${getI18nByKey(
            'Toolbar.Buttons.MyAccount'
          )}">
            <svg class="mobile-menu-button-svg no-pointer" role="presentation">
              <use xlink:href="#svg-account"></use>
            </svg>
          </button>`,
            dropdownOptions: accountDropdown,
            extraClasses: 'mod-account'
          })
        );
      }

      if (this.menu.showLogo) {
        $desktopMenuRight.append(
          renderToolbarLink({
            type: 'link',
            extraClasses: 'mod-logo',
            href: `https://${mainWebsiteDomainIncWWW}`,
            newWindow: true,
            svgID: 'logo',
            text: getI18nByKey('Branding.PoweredByPageTiger')
          })
        );
      }

      if (isFunction(window.ptiMenuRendered)) {
        window.ptiMenuRendered();
      }
    }

    if (menu.isMenu(this.menu)) {
      $head.append(`
        <style>
          ${menu.renderMenuStyles(this.menu, this.menu.items)}
        </style>
      `);

      renderMenu(this.menu, this.menu.items, this.guid);
    }

    if (pageTigerCookiePopupShouldBeShown) {
      const cookieBannerFormClass = 'js-cookie-form';

      document.querySelector('.' + cookieBannerFormClass)?.addEventListener('submit', e => {
        e.preventDefault();

        // @ts-ignore
        const hasAccepted = e.target.submitted === 'accept';

        $('.js-popup-cookie-notice').remove();
        $(`#${this.elementID}`).trigger('focus');

        const updatedPreferences = {
          advertising: hasAccepted,
          functional: hasAccepted,
          performance: hasAccepted
        };

        this.cookieManager.handleCookieUpdate(updatedPreferences);

        $('.js-popup-cookie-notice').trigger('focus');
        this.updatePageSize();
        this.ui.resize();
      });
    }

    const hasTouchEnabled = 'ontouchstart' in document.documentElement;
    var tooltips = [];

    if (!hasTouchEnabled) {
      const tips = [].slice.call(document.querySelectorAll('[data-tooltip]'));

      tooltips = tips.map(
        tip =>
          new Tooltip(tip, {
            container: document.body,
            placement: $(tip).parents('.toolbar').data('toolbar-placement') || 'bottom',
            title: tip.getAttribute('data-tooltip'),
            trigger: 'hover'
          })
      );
    }

    document.body.addEventListener('click', (/** MouseEvent */ { target }) => {
      if (target instanceof HTMLElement) {
        const { action, actionArg = '', delayTime } = target.dataset;
        const numericDelay = safeNumberFromString(delayTime).option(0);
        const clickedOnCornerFold =
          (action === 'ptiNextPage' || action === 'ptiPreviousPage') && Number(delayTime) > 0;

        if (clickedOnCornerFold) {
          // From the point the user clicks on the corner, prevent any more corner
          // folds until the page has fully turned.
          this.ui.forcePreventFolds = true;
        }

        if (action) {
          delay(numericDelay).then(() => {
            const actionArgInt = parseInt(actionArg);

            if (action === 'ptiGotoPage' && !Number.isNaN(actionArgInt)) {
              // If the action came from a menu item,
              // surpress restoring focus back to the menu root,
              // as this forces the page to scroll back to top
              const preventRestoreFocus =
                $(target).closest('.' + GLOBAL_MENU_MOUNTING_POINT_CLASS).length > 0;

              goToPage(null, actionArgInt, false, preventRestoreFocus);
            } else if (action === 'activateModule') {
              pageTigerAPI.activateModule(actionArg);
            } else if (action === 'ptiSearch') {
              window.ptiSearch(actionArg);
            } else {
              /* This is the old meh way of doing things. */
              mapFunctionNameToFunction(action)();
            }
          });

          if (target.getAttribute('data-tooltip')) {
            window.setTimeout(() => {
              tooltips.map(tooltip => tooltip.hide());
            }, 700);
          }
        }
      }
    });

    $('.toolbar-popup-list-item-button').on(
      'mouseenter',
      /** MouseEvent */ mouseEvent => {
        mouseEvent.target.focus();
      }
    );

    const modOnTop = 'mod-on-top';
    const navBarClass = '.js-nav-bar';

    const closeOpenPopUps = () => {
      $('.js-toggle-toolbar-popup[aria-expanded=true]')
        .attr('aria-expanded', 'false')
        .next()
        .fadeOut(300)
        .attr('aria-hidden', 'true');

      $(navBarClass).removeClass(modOnTop);
    };

    $body.on('click', ({ target }) => {
      if (!target.classList.contains('toolbar-popup')) {
        closeOpenPopUps();
      }
    });

    $('.js-toggle-toolbar-popup').on('click', function click(e) {
      e.stopPropagation();
      closeOpenPopUps();
      const $this = $(this);

      $this
        .attr('aria-expanded', 'true')
        .next()
        .fadeIn(300)
        .attr('aria-hidden', 'false')
        .find('.toolbar-popup-list-item-button')
        .get(0)
        .focus();

      $this.parents(navBarClass).addClass(modOnTop);
    });

    /**
     * Close toolbar menu when pressing escape
     */
    $body.on('keyup', function (e) {
      if (e.key == 'Escape' && !e.target.classList.contains('toolbar-popup')) {
        closeOpenPopUps();
      }
    });

    // Output Side Nav
    if (this.onlineMagSideNavBehaviour !== 'hidden' && this.onlineMagShowPaging) {
      /**
       * @param {string} sideNavBehaviour
       * @returns {string}
       */
      const getAdditionalClassName = sideNavBehaviour => {
        switch (sideNavBehaviour) {
          case 'always visible':
            return 'always';

          case 'show on hover':
            return 'mod-appear-onhover';

          case 'on hover and after paging':
            return 'mod-appear-onhover afterPaging';

          default:
            return 'always';
        }
      };
      const extraClasses = getAdditionalClassName(this.onlineMagSideNavBehaviour);

      $('body').append(`
      <nav aria-label="paging navigation" data-t="paging-nav" class="js-paging-nav">
        ${pagingButton({
          action: 'ptiPreviousPage',
          ariaHidden: 'true',
          extraClasses,
          modClass: 'mod-prev',
          text: getI18nByKey('PreviousPage')
        })}
        ${pagingButton({
          action: 'ptiNextPage',
          ariaHidden: 'true',
          extraClasses,
          modClass: 'mod-next',
          text: getI18nByKey('NextPage')
        })}
    </nav>`);
    }

    $(window).on('orientationchange', () => {
      $('window').trigger('resize');
    });

    const $innerContainer = $('#' + window.containerID);

    getSkipLinks(document.location.href, this).forEach(link => $innerContainer.prepend(link));

    this.zoom = layout === 'single';

    // If we're using a pageTemplateId then we may only load 1 or 2 pages.
    // We know this, due to the fact the panels aren't fully populated.
    // const pagesWithPanels = this.onlineMagPages.items.filter(page => page.panels.length > 0).length;
    // const magPages = this.onlineMagPages.length;
    // const pages = Math.min(magPages, pagesWithPanels) - 1;
    // this.config.firstPage = clamp(0, pages, getRequestedInitialPage(window));

    this.config.firstPage = getRequestedInitialPage(window);

    if (!this.zoom) {
      if (this.config.alwaysOpened) {
        // Set left page as index
        if (this.config.firstPage % 2 === 1) {
          this.config.firstPage = this.config.firstPage - 1;
        }
      } else if (
        this.config.firstPage < this.onlineMagPages.length - 1 &&
        this.config.firstPage % 2 === 1
      ) {
        // Not last page of set the right page as index
        this.config.firstPage = this.config.firstPage + 1;
      }
    }

    // Zero based
    this.currentPageIndex = this.config.firstPage;

    window.errorReporter.updateData({
      currentPageIndex: this.currentPageIndex,
      emailAddress: this.emailAddress === '' ? null : this.emailAddress,
      onlineMagID: onlineMagID,
      onlineMagIssueID: this.onlineMagIssueID
    });

    // Check that the user is able to view the page that came in via the URL
    // Perhaps there's mandatory interactivity before the page they want to view.
    if (this.currentPageIndex > 0) {
      const newPageIndex = getFirstPageIndexWithIncompleteRequiredModules(
        this.onlineMagPages.items
      );

      if (this.currentPageIndex > newPageIndex && newPageIndex !== -1) {
        this.currentPageIndex = newPageIndex;
        checkRequiredInteractivityOnCurrentPage(this);
      }
    }

    this.updatePageSize();
    window.switchToolbar(this.zoom);

    window.clickLinkHandler = e => {
      if (e.target instanceof HTMLButtonElement || e.target instanceof HTMLAnchorElement) {
        e.target.blur();
        const id = e.target.getAttribute('data-id');
        const mod = window.getByID(this.allOnlineMagPageLinks, Number(id));
        mod?.clicked();
      }
    };

    // Perform actions when clicking interactivity
    window.addEventListener('click', e => {
      if (e.target instanceof HTMLButtonElement) {
        const { target } = e;
        const { id, interactivityAction, moduleType } = target.dataset;
        const actionFnExists = typeof window[interactivityAction] === 'function';

        if (interactivityAction && actionFnExists) {
          const modType = parseInt(moduleType);
          const modID = parseInt(id);

          window[interactivityAction](null, modType, modID);
        }
      }
    });

    $(window).on('resize', this.windowResize);

    this.windowResize();

    const events = [
      { action: videoEvents.seek(this), eventName: 'seeking' },
      { action: videoEvents.ended(this), eventName: 'ended' },
      { action: videoEvents.play(this), eventName: 'play' },
      { action: videoEvents.pause(this, false), eventName: 'pause' },
      { action: videoEvents.timelineUpdate(this), eventName: 'timeupdate' },
      { action: videoEvents.metaDataLoaded(this), eventName: 'loadedmetadata' }
    ];

    events.map(({ eventName, action }) => {
      window.addEventListener(
        eventName,
        e => {
          if (e.target instanceof HTMLVideoElement) {
            action(e);
          }
        },
        true
      );
    });

    if (this.config.backgroundComplete) {
      this.config.backgroundComplete.call(this);
    }

    if (this.config.onNavigationRendered) {
      this.config.onNavigationRendered.call(this, this.containerElementID);
    }

    const nav = [].slice.call(
      document.querySelectorAll('.js-nav-top-level, .js-desktop-menu-right')
    );
    // Starting with 10, as that's the padding.
    const menuWidth = nav.reduce((acc, item) => item.getBoundingClientRect().width + acc, 10);
    const clampedResponsiveWidth = clamp(845, menuWidth, menuWidth);
    const $desktopMenuBar = $('.ptiMenuBarBG');
    const $mobileMenuBar = $('.mobile-menu');
    const $slideoutNav = $('.slideout-nav');
    const $hideOnLargeScreen = $('.hide-on-large-screen');
    const $hideOnSmallScreen = $('.hide-on-small-screen');
    const responsiveBreakPoint = this.onlineMagFrameStretchToFit
      ? clampedResponsiveWidth
      : this.onlineMagFrameWidth;

    const mediaQueryAction = ({ matches }) => {
      if (matches) {
        $desktopMenuBar.css({ display: 'none' });
        $mobileMenuBar.css({ display: 'flex' });
        $slideoutNav.css({ display: 'block' });

        $hideOnLargeScreen.css({ display: 'block' });
        $hideOnSmallScreen.css({ display: 'none' });
      } else {
        $desktopMenuBar.css({ display: 'flex' });
        $mobileMenuBar.css({ display: 'none' });
        $slideoutNav.css({ display: 'none' });
        $hideOnLargeScreen.css({ display: 'none' });
        $hideOnSmallScreen.css({ display: 'block' });

        // If the document doesn't have a menu, toggleNav doesn't exist.
        if (window.toggleNav) {
          window.toggleNav(false);
        }
      }
    };

    mediaQueryAction({
      matches: document.documentElement.clientWidth < responsiveBreakPoint
    });

    window.matchMedia(`(max-width: ${responsiveBreakPoint}px)`).addListener(mediaQueryAction);
    const pageNumber = lastVisitPageIndex + 1;

    if (objOnlineMagIssue.currentPageIndex === 0 && lastVisitPageIndex > 0) {
      //  Prompt user to see if they wish to continue where they last left off
      $.fn.ptimessage.showYesNo(
        getI18nByKey('Messages.LastVisitedPage', `${pageNumber}`),
        500,
        200,
        function yes() {
          var isExcceedingBounds = false;

          if (!objOnlineMagIssue.zoom) {
            isExcceedingBounds = objOnlineMagIssue.pages[lastVisitPageIndex + 1] === undefined;
          }

          const adjustment = isExcceedingBounds && objOnlineMagIssue.config.alwaysOpened ? 1 : 0;

          objOnlineMagIssue.config.firstPage = lastVisitPageIndex - adjustment;

          objOnlineMagIssue.currentPageIndex = lastVisitPageIndex - adjustment;
        },
        null,
        function closeModal() {
          window.ptiHookupLeftRightArrows(objOnlineMagIssue.containerElementID);
          objOnlineMagIssue.showPublication();
        }
      );
    } else {
      objOnlineMagIssue.showPublication();
    }
  }

  /**
   * @this {OnlineMag}
   * @returns {void}
   */
  showPublication() {
    const $head = $(document.head);

    if (this.documentAnalytics.googleAnalyticsCode) {
      $head.append(
        `${googleAnalyticsMarkUp(
          this.documentAnalytics.googleAnalyticsCode,
          this.cookiePerformanceAccepted
        ).trim()}`
      );
    }

    if (this.documentAnalytics.facebookCode) {
      $head.append(
        `${facebookAnalyticsMarkUp(
          this.documentAnalytics.facebookCode,
          this.cookieAdvertisingAccepted
        ).trim()}`
      );
    }

    this.analytics = new Analytics(this.documentAnalytics, window.gtag, window.fbq);

    /**
     * @param {OnlineMag["viewingInterface"]} uiName
     */
    const initUI = uiName =>
      lazilyGetUI(uiName)
        .then(ui => {
          // @ts-ignore
          this.ui = new ui(this);
          this.ui.show(this.zoom);
          $.fn.ptibox.hideLoading();

          if (window.parent && this.previewMode) {
            const target = 'https://' + this.host.replace('view.', 'admin.');

            window.parent.postMessage(
              {
                type: DESIGNER_MESSAGE_EVENTS.LOADED
              },
              target
            );
          }
        })
        .catch(console.error);

    if (hasVirtualKeyboard()) {
      import(/* webpackChunkName: "virtual-keyboard" */ './virtual-keyboard')
        .then(({ virtualKeyboard }) => virtualKeyboard())
        .catch(console.error);
    }

    initUI(this.viewingInterface);
  }

  /**
   * @param {CookiePreferences} cookiePrefs
   * @returns {void}
   */
  updateCookiePreferences({ advertising, functional, performance }) {
    this.cookieAdvertisingAccepted = advertising;
    this.cookieFunctionalAccepted = functional;
    this.cookiePerformanceAccepted = performance;

    if (this.documentAnalytics.googleAnalyticsCode) {
      this.analytics.toggleGoogleConsent(performance);
    }

    if (this.documentAnalytics.facebookCode) {
      this.analytics.toggleFacebookConsent(advertising);
    }
  }

  /**
   * @returns {void}
   */
  updatePageSize() {
    const {
      isSmallScreen,
      onlineMagFrameWidth,
      onlineMagPages,
      onlineMagSideNavBehaviour,
      onlineMagShowPaging,
      heightScale,
      onlineMagFrameHeight,
      onlineMagWidth,
      onlineMagFrameStretchToFit,
      onlineMagFrameStretchToFitSingle,
      onlineMagFrameLeftRightMargin,
      onlineMagFrameTopBottomMargin,
      orientation,
      previewMode
    } = this;
    let doublePageHeight = this.sizes.doublePage.pageHeight;
    let doublePageWidth = this.sizes.doublePage.pageWidth;
    let singlePageHeight = this.sizes.singlePage.pageHeight;
    let singlePageWidth = this.sizes.singlePage.pageWidth;

    const windowWidth = document.documentElement.clientWidth;
    const forceResponsiveView = onlineMagFrameWidth >= windowWidth;

    const arrowsHidden = !(
      onlineMagSideNavBehaviour !== 'hidden' &&
      onlineMagShowPaging &&
      onlineMagPages.length > 1
    );

    let leftRightMargin = getLeftRightMarginSize(arrowsHidden, isSmallScreen, orientation);
    let topBottomMargin = 5;

    if (previewMode) {
      leftRightMargin = 0;
      topBottomMargin = 0;
    }

    // -----------------
    // START DOUBLE PAGE
    // -----------------
    if (onlineMagFrameStretchToFit || forceResponsiveView) {
      doublePageWidth = windowWidth;
      doublePageHeight = calculateAvailableHeight() - onlineMagFrameTopBottomMargin * 2;
    } else {
      leftRightMargin = onlineMagFrameLeftRightMargin;
      topBottomMargin = onlineMagFrameTopBottomMargin;
      doublePageWidth = onlineMagFrameWidth;
      doublePageHeight = onlineMagFrameHeight;
    }

    const maxWidth = windowWidth - leftRightMargin * 2;
    const maxHeight = doublePageHeight - topBottomMargin * 2;
    const examplePageWidth = 100;
    const examplePageHeight = examplePageWidth * heightScale;
    const heightRatio = maxHeight / examplePageHeight;
    const doubleWidthRatio = maxWidth / (examplePageWidth * 2);
    const doubleMinRatio = doubleWidthRatio < heightRatio ? doubleWidthRatio : heightRatio;
    const singleWidthRatio = maxWidth / examplePageWidth;
    const singleMinRatio = singleWidthRatio < heightRatio ? singleWidthRatio : heightRatio;

    doublePageWidth = Math.round(doubleMinRatio * examplePageWidth);
    doublePageHeight = Math.round(doubleMinRatio * examplePageHeight);

    // -----------------
    // START SINGLE PAGE
    // -----------------
    const fixedWidth =
      (!onlineMagFrameStretchToFit || onlineMagFrameStretchToFitSingle === 'no stretch') &&
      !forceResponsiveView;

    const stretchToHeightAndWidth = onlineMagFrameStretchToFitSingle === 'width and height';
    const stretchToWidth = onlineMagFrameStretchToFitSingle === 'width' || forceResponsiveView;

    if (fixedWidth) {
      singlePageWidth = onlineMagWidth * 2;
      singlePageHeight = singlePageWidth * heightScale;
    } else if (stretchToHeightAndWidth) {
      singlePageWidth = Math.round(singleMinRatio * examplePageWidth);
      singlePageHeight = Math.round(singleMinRatio * examplePageHeight);
    } else if (stretchToWidth) {
      singlePageWidth = windowWidth - leftRightMargin * 2;
      singlePageHeight = singlePageWidth * heightScale;
    }

    // Mobile UIs tend to shink and expand when the user scrolls. As a result,
    // triggers a resize and causes a fresh call to this function. This can
    // cause and unexpected change to the viewing mode and switch from single
    // page to double page.
    this.changedSincePreviousResize =
      this.sizes.doublePage.pageHeight !== doublePageHeight ||
      this.sizes.doublePage.pageWidth !== doublePageWidth ||
      this.sizes.singlePage.pageHeight !== singlePageHeight ||
      this.sizes.singlePage.pageWidth !== singlePageWidth;

    this.sizes.singlePage.pageWidth = singlePageWidth;
    this.sizes.singlePage.pageHeight = singlePageHeight;
    this.sizes.doublePage.pageWidth = doublePageWidth;
    this.sizes.doublePage.pageHeight = doublePageHeight;
  }

  /**
   * @returns {string}
   */
  elementMarkup() {
    const isHtmlMode = !isJpgView(this.viewingInterface);
    const preventRightClickAttr = isHtmlMode ? '' : 'oncontextmenu="return false;"';
    const htmlClass = isHtmlMode ? `mod-html` : '';
    const dataMod = getTestSuffix(this.viewingInterface);
    var viewMode = 'normal-view';

    if (this.config.accessibleInterface.toLowerCase() === 'h') {
      viewMode = 'accessible-view';
    }

    if (this.config.accessibleInterface.toLowerCase() === 'c') {
      viewMode = 'contrast-view';
    }

    return `
      <div id="${this.elementID}"
         tabindex="-1"
         ${preventRightClickAttr}
         data-t="document-wrapper-${dataMod}"
         class="container-inner ${htmlClass} ${viewMode}">
      </div>`;
  }

  /**
   * @returns {void}
   */
  singlePageView() {
    const isPageTurnUI = isPageTurnMode(this.viewingInterface);

    if (!this.zoom) {
      if (isPageTurnUI) {
        if (this.config.alwaysOpened) {
          if (this.currentPageIndex < 0) {
            this.currentPageIndex = 0;
          }
        } else {
          if (this.currentPageIndex % 2 == 0) {
            this.currentPageIndex = this.currentPageIndex + -1;
          }

          if (this.currentPageIndex < 0) {
            this.currentPageIndex = 0;
          }
        }
      }

      if (this.ui) {
        this.ui.show(true);
      }

      window.switchToolbar(this.zoom);
    }
  }

  // TODO FIND A PLACE FOR THESE TO GO.
  // fireEvents(EVENT_TYPES.ON_COMPLETE, objModule.chain);
  // if (this.viewingInterface === STACKABLE) {
  // this.ui.redrawPages();
  // }
  // $(getModuleSelector(objModule)).removeClass('mod-mandatory');

  // /**
  //  * @param {string} pageID
  //  * @param {ModuleType} moduleType
  //  * @param {string} moduleID
  //  * @returns {void}
  //  */
  // setModuleComplete(pageID, moduleType, moduleID) {
  //   var moduleFound = false;
  //   const pageLength = this.onlineMagPages.length;

  //   for (var pageIndex = 0; pageIndex < pageLength; ++pageIndex) {
  //     if (moduleFound === true) {
  //       break;
  //     }

  //     const moduleLength = this.onlineMagPages.items[pageIndex].modules.length;
  //     const page = this.onlineMagPages.items[pageIndex];

  //     if (page.id == pageID) {
  //       for (var modIndex = 0; modIndex < moduleLength; ++modIndex) {
  //         const objModule = page.modules[modIndex];

  //         if (objModule.moduleType == moduleType && objModule.id == moduleID) {
  //           // This is a temp hack. The video setModuleComplete fires when the video loads, not when
  //           // the video is complete, so we will fire the video chain events inside of
  //           // OnlineMagPageVideo.prototype.videoPlayed
  //           if (
  //             !(objModule.moduleType === MODULES.VIDEO || objModule.moduleType === MODULES.COMP)
  //           ) {
  //             fireEvents(EVENT_TYPES.ON_COMPLETE, objModule.chain);
  //           }

  //           objModule.isComplete = true;
  //           $(getModuleSelector(objModule)).removeClass('mod-mandatory');

  //           moduleFound = true;
  //           break;
  //         }
  //       }
  //     }
  //   }
  // }

  onModuleComplete(module) {
    if (this.viewingInterface === STACKABLE) {
      this.ui.redrawPages();
    }

    $(getModuleSelector(module)).removeClass(MANDATORY_CLASS);
  }

  /**
   * @returns {void}
   */
  doublePageView() {
    const isPageTurnUI = isPageTurnMode(this.viewingInterface);

    if (this.zoom) {
      if (isPageTurnUI) {
        if (this.config.alwaysOpened) {
          if (this.currentPageIndex % 2 == 1) {
            this.currentPageIndex = this.currentPageIndex - 1;
          }

          if (this.currentPageIndex > this.onlineMagPages.length - 1) {
            this.currentPageIndex = this.onlineMagPages.length - 1;
          }
        } else {
          if (this.currentPageIndex % 2 == 1) {
            this.currentPageIndex = this.currentPageIndex + 1;
          }

          if (this.currentPageIndex > this.onlineMagPages.length - 1) {
            this.currentPageIndex = this.onlineMagPages.length - 1;
          }
        }
      }

      if (this.ui) {
        this.ui.show(false);
      }

      window.switchToolbar(this.zoom);
    }
  }

  /**
   * @param {Page | null} leftPage
   * @param {Page | null} rightPage
   * @returns {void}
   */
  pagesViewed(leftPage, rightPage) {
    const baseFileName = `/${this.documentName}/${this.versionName}`;
    const currentPageNumbers = [
      ...(leftPage ? [leftPage.pageIndex + 1] : []),
      ...(rightPage ? [rightPage.pageIndex + 1] : [])
    ];
    const pages = currentPageNumbers.join('-');

    window.errorReporter.updateData({
      currentPageIndex: leftPage?.pageIndex || rightPage?.pageIndex
    });

    $('.js-current-page').text(
      `${getI18nByKey(
        'Toolbar.Pagination.PageNumberOfTotalPages',
        `${pages}`,
        `${this.onlineMagPages.length}`
      )}`
    );

    const pageNames = currentPageNumbers.join(' and ');
    const ariaLiveText =
      currentPageNumbers.length > 1
        ? getI18nByKey('Messages.DisplayingPages', `${pageNames}`, `${this.onlineMagPages.length}`)
        : getI18nByKey('Messages.DisplayingPages', `${pageNames}`, `${this.onlineMagPages.length}`);

    // This only updated the text on the page turn UI
    $(`.${GLOBAL_ARIA_LIVE_CLASS}`).text(ariaLiveText);

    // Only log to analytics if we're not in attract mode
    if (!this.config.attractMode || !attractModeStillActive(this.containerElementID)) {
      const pageIDs = `${leftPage?.id ?? 0},${rightPage?.id ?? 0}`;
      const url = `${this.baseUrl}?M=3&VID=${this.onlineMagIssueVisitID}&PNS=${pageIDs}`;

      if (this.ui && this.onlineMagClickableAreaStyle !== 'none') {
        this.ui.glow();
      }

      $.ajax({
        url,
        dataType: 'jsonp'
      });

      /**
       * @param {Page} page
       * @returns {string}
       */
      const getFileName = page => `${baseFileName}/page${page.pageIndex + 1}.htm`;

      if (leftPage) {
        this.analytics.firePageView(leftPage.title, getFileName(leftPage));
      }

      if (rightPage) {
        this.analytics.firePageView(rightPage.title, getFileName(rightPage));
      }
    }

    if (leftPage?.pageIndex === 0 || rightPage?.pageIndex === 0) {
      if (this.firstPageAlreadyLoaded === false && this.config.jumpToPageOnFirstPageLoad > 0) {
        window.setTimeout(() => {
          window.ptiGotoPageIfOnCover(
            this.containerElementID,
            this.config.jumpToPageOnFirstPageLoad
          );
        }, this.config.jumpToPageOnFirstPageLoadDelay);
      }

      this.firstPageAlreadyLoaded = true;
    }
  }

  /**
   * @param {number[]} pageIndexes - A list of viewed pages
   * @returns {void}
   */
  stackablePagesViewed(pageIndexes) {
    const { analytics, baseUrl, versionName, documentName, onlineMagIssueVisitID, onlineMagPages } =
      this;
    const pageIDs = pageIndexes.map(getPageIDFromIndex(onlineMagPages));
    const url = `${baseUrl}?M=3&VID=${onlineMagIssueVisitID}&PNS=${pageIDs.join(',')}`;
    const baseFileName = `/${documentName}/${versionName}`;

    const getFileName = page => `${baseFileName}/page${page.pageIndex + 1}.htm`;

    window.errorReporter.updateData({
      currentPageIndex: pageIndexes[0]
    });

    if (!this.config.attractMode || !attractModeStillActive(this.containerElementID)) {
      $.ajax({
        url,
        dataType: 'jsonp'
      }).then(function response(pobjReturn) {
        if (pobjReturn.returnStatus !== '1') {
          console.warn(`JSON ERROR: ${pobjReturn.returnMessage}`);
        }
      });

      pageIndexes.forEach(pageIndex => {
        const page = onlineMagPages.items[pageIndex];

        analytics.firePageView(page.title, getFileName(page));
      });
    }
  }

  /**
   * @returns {void}
   */
  showContents() {
    var objOnlineMagIssue = this;
    var strContainerElementID = objOnlineMagIssue.containerElementID;
    var strHTML = `<h2 class="js-contents-heading" tabindex="-1" style="margin-top: 0;">
    ${getI18nByKey('Contents.Title')}</h2>
    ${this.getContentsMarkup(renderContentsPage)}
  `;

    // @ts-ignore
    $().ptibox({
      cssClass: 'system-modal',
      borderColour: objOnlineMagIssue.onlineMagDefaultPopupBorderColour,
      callbackOnClose() {
        window.ptiHookupLeftRightArrows(strContainerElementID);
      },
      itemArray: [
        $.fn.ptibox.generateItemObject(
          strHTML,
          '',
          '',
          getI18nByKey('Contents.Title'),
          null,
          6 * this.thumbnailWidth + 130,
          this.onlineMagFrameHeight - 60,
          null,
          false
        )
      ],
      showInfo: 2,
      showPlayButton: false
    });
  }

  /**
   * @param {(page: OnlineMagPage, style: string) => string} renderFn
   * @returns {string}
   */
  getContentsMarkup(renderFn) {
    const { config, onlineMagPages } = this;
    const heightAndWidth = `width: ${this.thumbnailWidth}px; height: ${this.thumbnailHeight}px;`;
    /**
     * @param {OnlineMagPage} page
     * @returns {string}
     */
    const imageStyle = page =>
      `style="border: 1px solid #cbcbcb; ${heightAndWidth} background: url(${page.thumbnailUrl}) no-repeat;"`;
    const blankPage = `<div class="contents-item mod-blank" style="${heightAndWidth}"></div>`;
    const { alwaysOpened } = config;
    const newPages = alwaysOpened ? onlineMagPages.items : [undefined, ...onlineMagPages.items];
    const pagesWith = splitEvery(2, newPages);
    const pageMarkup = pagesWith
      .map(
        ([leftPage, rightPage]) => `
        <div class="contents-page-spread">
          ${leftPage ? renderFn(leftPage, imageStyle(leftPage)) : blankPage}
          ${rightPage ? renderFn(rightPage, imageStyle(rightPage)) : blankPage}
        </div>`
      )
      .join('');

    return `<div class="contents-wrapper">${pageMarkup}</div>`;
  }

  /**
   * @param {number} pageIndex
   * @returns {void}
   */
  closeContentsGotoPage(pageIndex) {
    const { containerElementID } = this;

    $('.pticontent .pti_thumb .pti_thumbimage').off();
    delay(500).then(() => window.ptiGotoPage(containerElementID, pageIndex));

    $().ptibox.close(function closeModal() {
      window.ptiHookupLeftRightArrows(containerElementID);
    });
  }

  /**
   * @returns {void}
   */
  enterPageNumber() {
    const magIssue = this;
    const { containerElementID, onlineMagDefaultPopupBorderColour, onlineMagPages } = this;
    const formClassName = 'ptienterpagenumber';
    const strHTML = `
      <form class="${formClassName}">
        <span class="form-error js-paging-error" aria-live="polite"></span>
        <div id="ptienterpagenumberdetail" class="ptienterpagenumberdetail">
          <label for="ptienterpagenumbertext" id="ptienterpagenumberlabel">${getI18nByKey(
            'GoToPage.Inputs.GoToPage'
          )}</label>
          <input type="text" id="ptienterpagenumbertext" class="form-element-input" autocomplete="off" data-t="page-number-input" maxlength="50" >
          <button type="submit" id="ptienterpagenumberbutton" tabindex="0" data-t="go-to-page-button" class="ptibox_buttonBG lightbox-button-bg js-go-to-page-button">
            <span class="lightbox-button-bg-span">${getI18nByKey('GoToPage.Buttons.Go')}</span>
          </button>
      </form>
      <p id="ptienterpagenumberdesc">${getI18nByKey(
        'GoToPage.Hints.EnterPageBetween',
        '1',
        `${onlineMagPages.length}`
      )}</p>
    </div>`;

    // @ts-ignore
    $().ptibox({
      cssClass: 'system-modal',
      borderColour: onlineMagDefaultPopupBorderColour,
      callbackOnClose() {
        getDomElement(`.${formClassName}`).map(
          /** @param {HTMLFormElement} el */ el => {
            el.removeEventListener('submit', magIssue.enterPageNumberNow);
          }
        );

        window.ptiHookupLeftRightArrows(containerElementID);
      },
      callbackOnShow() {
        getDomElement(`.${formClassName}`).map(
          /** @param {HTMLFormElement} el */ el => {
            el.addEventListener('submit', magIssue.enterPageNumberNow);
          }
        );

        $('#ptienterpagenumbertext').trigger('focus');
      },
      itemArray: [
        $.fn.ptibox.generateItemObject(
          strHTML,
          '',
          '',
          getI18nByKey('GoToPage.Title'),
          null,
          330,
          140,
          null,
          false
        )
      ],
      showInfo: 2,
      showPlayButton: false
    });
  }

  /**
   * @param {SubmitEvent} e
   * @returns {void}
   */
  enterPageNumberNow(e) {
    e.preventDefault();

    const objPageTurn = getInstance();
    const { containerElementID } = objPageTurn;
    const $input = $('#ptienterpagenumbertext');
    const strPageNo = $input.val();
    const pageNumber = parseInt(strPageNo.toString());

    $input.removeAttr('aria-invalid');

    if (
      Number.isInteger(pageNumber) &&
      pageNumber > 0 &&
      pageNumber <= this.onlineMagPages.length
    ) {
      $().ptibox.close(() => {
        // Set a timeout to ensure the mask has been hidden before calling gotoPage which may throw an alert for unviewed important content
        delay(10).then(() => window.ptiGotoPage(containerElementID, pageNumber - 1));
      });
    } else {
      $('.js-paging-error')
        .addClass('mod-show')
        .text(getI18nByKey('GoToPage.Messages.InvalidPageNumber'));
      $input.attr('aria-invalid', 'true');
    }
  }

  /**
   * @returns {void}
   */
  printSelection() {
    const {
      containerElementID,
      onlineMagDefaultPopupBorderColour: borderColour,
      onlineMagIssueUrl
    } = this;
    const printFormClass = 'js-print-form';
    const printSelectAllClass = 'js-print-select-all';
    const markup = `
    <form class="${printFormClass}" tabindex="-1" aria-label="Select pages to print">
      <div style="display: flex; justify-content: space-between; margin-bottom: 15px;">
      <h2 class="js-print-heading" tabindex="-1" style="margin: 0;">${getI18nByKey(
        'Print.Title'
      )}</h2>
        <div class="contents-page-select-all">
          <label for="pdf-select-all" class="select-all-label">${getI18nByKey(
            'DownloadPdf.SelectAll'
          )}</label>
          <input id="pdf-select-all" class="${printSelectAllClass} contents-page-select-all-checkbox" type="checkbox" checked="true" >
        </div>
      </div>
      <div>
        ${this.getContentsMarkup(renderCheckboxPage)}
      </div>

      <div style="display: flex; justify-content: center;">
        <button type="submit" class="form-element-button">${getI18nByKey(
          'Print.Buttons.Print'
        )}</button>
      </div>
    </form>`;

    // @ts-ignore
    $().ptibox({
      cssClass: 'system-modal',
      borderColour,
      callbackOnClose() {
        window.ptiHookupLeftRightArrows(containerElementID);
      },
      callbackOnShow() {
        /** @type {JQuery<HTMLInputElement>} */
        const $selectAllCheckbox = $(`.${printSelectAllClass}`);

        /** @type {JQuery<HTMLFormElement>} */
        const $printForm = $(`.${printFormClass}`);

        /** @type {HTMLInputElement[]} */
        const allPageCheckBoxes = Array.from(
          document.querySelectorAll('.js-contents-page-checkbox')
        );
        const focusClass = 'mod-focus';

        $('.js-print-heading').trigger('focus');

        $selectAllCheckbox.off().on('change', function onChange(e) {
          allPageCheckBoxes.forEach(el => (el.checked = e.target.checked));
        });

        $(`.${printFormClass} .js-contents-page-checkbox`)
          .off()
          .on('blur', function onBlur(e) {
            const { target } = e.originalEvent;

            if (target instanceof HTMLInputElement) {
              target.parentElement.classList.remove(focusClass);
            }
          })
          .on('focus', function onFocus(e) {
            delay(100).then(() => {
              const { target } = e.originalEvent;

              if (target instanceof HTMLInputElement) {
                if (target.hasAttribute('data-focus-visible-added')) {
                  target.parentElement.classList.add(focusClass);
                }
              }
            });
          })
          .on('change', function onChange() {
            const headStatus = allPageCheckBoxes[0].checked;
            const allSame = [...allPageCheckBoxes].every(c => c.checked === headStatus);

            if (allSame) {
              $selectAllCheckbox.prop('indeterminate', false);
              $selectAllCheckbox.prop('checked', headStatus);
            } else {
              $selectAllCheckbox.prop('indeterminate', true);
            }
          });

        $printForm.off().on('submit', handlePopupFormSubmission(onlineMagIssueUrl, 'Print.htm'));
      },
      itemArray: [
        $.fn.ptibox.generateItemObject(
          markup,
          '',
          '',
          getI18nByKey('Print.Title'),
          null,
          6 * this.thumbnailWidth + 130,
          this.onlineMagFrameHeight - 60,
          null,
          false
        )
      ],
      showInfo: 2,
      showPlayButton: false
    });
  }

  /**
   * @param {ShareOptions} shareType
   * @param {number} pageNumber
   */
  shareNow(shareType, pageNumber) {
    const pageUrl = `${this.onlineMagIssueUrl}/page${pageNumber}.htm`;
    const shareUrl = `https://www.addtoany.com/add_to/${shareType}?linkurl=${encodeURIComponent(
      pageUrl
    )}`;

    if (shareType === 'native') {
      navigator
        .share({
          title: document.title,
          url: pageUrl
        })
        .catch(console.warn);
    } else {
      openWindow(shareUrl, '_blank');
    }

    this.analytics.fireShareEvent(shareType, `${getI18nByKey('Page')} ${pageNumber}`);
  }

  /**
   * @returns {void}
   */
  showShare() {
    const doc = this;
    const formClass = `js-share-form`;
    const { containerElementID, onlineMagPages, onlineMagDefaultPopupBorderColour } = doc;
    const pageIndexesSet = new Set([0]);

    currentVisiblePageIndexes(doc).forEach(pageIndexesSet.add, pageIndexesSet);

    const pageIndexes = [...pageIndexesSet];

    /**
     * @param {number} index
     * @returns {string}
     */
    const pageMarkup = index => {
      const page = onlineMagPages.items[index];

      return page
        ? renderRadioPage(
            page,
            `style="border: 1px solid #c3c3c3; height: ${doc.thumbnailHeight}px; width: ${doc.thumbnailWidth}px; background: url(${page.thumbnailUrl}) no-repeat;"`
          )
        : '';
    };

    const markup = `
      <div class="ptishare" data-t="share-modal">
        <h2 style="margin: 0;">${getI18nByKey('Share.Title')}</h2>
        <p id="ptishareinstructions">${getI18nByKey('Share.SelectPage')}</p>
        <form class="${formClass}">
          <div>
            <fieldset name="share-page-input" style="display:flex; flex-wrap: wrap;">
              ${pageIndexes.map(pageMarkup).join('')}
            </fieldset>
          </div>

          <div style="margin-top: 50px;">
            ${
              navigator.share
                ? `<button class="share-button mod-native js-share-native" onclick="this.form.submitted=this.value;" name="native" value="native" type="submit" title="${getI18nByKey(
                    'Share.Buttons.Share'
                  )}">
                  <span class="visually-hidden">${getI18nByKey('Share.Buttons.Share')}</span>
                </button>`
                : ``
            }
            ${renderShareButton('Facebook', getI18nByKey('Share.Facebook'))}
            ${renderShareButton('Twitter', getI18nByKey('Share.Twitter'))}
            ${renderShareButton('Linkedin', getI18nByKey('Share.Linkedin'))}
          </div>
        </form>
      </div>
        `;

    // @ts-ignore
    $().ptibox({
      cssClass: 'system-modal',
      borderColour: onlineMagDefaultPopupBorderColour,
      callbackOnClose() {
        window.ptiHookupLeftRightArrows(containerElementID);
      },
      callbackOnShow() {
        $(`.${formClass}`)
          .off()
          .on('submit', e => {
            e.preventDefault();

            /** @type {ShareOptions} */
            // @ts-ignore
            const shareType = e.target.submitted;

            const pageNumber = compose(
              chain(getPageNumberFromDataSet),
              getDomElement
            )(`.${formClass} input[type="radio"]:checked`).option('1');

            doc.shareNow(shareType, parseInt(pageNumber));
          });
      },
      itemArray: [
        $.fn.ptibox.generateItemObject(
          markup,
          '',
          '',
          getI18nByKey('Share.Title'),
          null,
          this.thumbnailWidth * 3 + 100 < 420 ? 420 : this.thumbnailWidth * 3 + 100,
          this.thumbnailHeight + 180,
          null,
          false
        )
      ],
      showInfo: 2,
      showPlayButton: false
    });
  }

  /**
   * @returns {void}
   */
  showSearch(menuID) {
    const backgroundColour = this.onlineMagDefaultPopupButtonBGColour;
    const textColour = this.onlineMagDefaultPopupButtonTextColour;
    const { containerElementID } = this;
    const inputClass = 'js-search-input';
    const markup = `
    <form class="js-search-form" data-t="search-form" data-menuID="${menuID}" role="search">
      <span aria-live="polite" class="form-error js-search-results-error"></span>
      <div class="search-form-input-container" style="display: flex;">
        <label class="search-form-input-label" data-t="search-label" for="ptitoolssearchtext">
          <span class="visually-hidden">${getI18nByKey('Search.Labels.Search')}</span>
          <input class="form-element-input mod-full-width ${inputClass} search-form-input"
          data-t="search-input"
          id="ptitoolssearchtext"
          maxlength="50"
          placeholder="${
            menuID ? getI18nByKey('Search.SearchMenu') : getI18nByKey('Search.SearchDocument')
          }"
          type="text" >
        </label>
        <button class="form-element-button js-search-button search-form-button" data-t="search-button" style="background-color: ${backgroundColour}; color: ${textColour}" type="sumbit">${getI18nByKey(
      'Search.Buttons.Search'
    )}</button>
      </div>
    </form>

    <span class="visually-hidden js-results-a11y-text" aria-live="polite"></span>
    <span class="search-results-text js-results-text"></span>
    <div class="js-results-container"></div>
    `;

    // @ts-ignore
    $().ptibox({
      cssClass: 'system-modal',
      borderColour: this.onlineMagDefaultPopupBorderColour,
      callbackOnClose() {
        window.ptiHookupLeftRightArrows(containerElementID);
      },
      callbackOnShow: () => {
        $(`.${inputClass}`).trigger('focus');

        // // The browser *should* translate its validation messages based on browser's language,
        // // but I think for that you need to set the browser document language... so dealing with it
        // // here just in case...

        // const $elem = $(`.${inputClass}`).get(0);
        // const validity = $elem.validity;

        // if (validity.valueMissing) {
        //   $elem.setCustomValidity(getI18nByKey('Search.MissingField'));
        // } else {
        //   $elem.setCustomValidity('');
        // }

        // $elem.reportValidity();

        getDomElement('.js-search-form').map(el => {
          el.addEventListener('submit', e => {
            e.preventDefault();
            const searchTerm = $(`.${inputClass}`).val().toString();

            const menuID = $(e.target).data('menuid');
            const parsedMenuID = menuID !== undefined && !isNaN(menuID) ? Number(menuID) : null;

            this.searchNow(searchTerm, parsedMenuID);
          });
        });
      },
      itemArray: [
        $.fn.ptibox.generateItemObject(
          markup,
          '',
          '',
          getI18nByKey('Search.Title'),
          null,
          700,
          500,
          null,
          false
        )
      ],
      showCloseButton: true,
      showInfo: 2,
      showPlayButton: false
    });
  }

  /**
   * @returns {void}
   */
  downloadPDF() {
    const downloadAllClass = 'js-download-all';
    const downloadSelectedClass = 'js-download-selected';
    const markup = `
    <div>
      <h2 class="popup-heading">${getI18nByKey('DownloadPdf.Title')}</h2>
      <div style="display: flex">
        <a href="https://${this.host}/${this.documentName}/${
      this.versionName
    }/PDF.pdf" target="_blank" rel="noopener noreferrer" class="popup-action-button mod-download-all ${downloadAllClass} text-download-button" data-t="pdf-download-all">
          <span>${getI18nByKey('DownloadPdf.DownloadPdfAllPages')}</span>
        </a>
        <button class="popup-action-button mod-download-selected ${downloadSelectedClass} text-download-button" data-t="pdf-download-selected">
          <span>${getI18nByKey('DownloadPdf.SelectPagesToDownload')}</span>
        </button>
    </div>`;

    // @ts-ignore
    $().ptibox({
      cssClass: 'system-modal',
      borderColour: this.onlineMagDefaultPopupBorderColour,
      callbackOnClose() {
        window.ptiHookupLeftRightArrows(this.containerElementID);
      },
      callbackOnShow: () => {
        $(`.${downloadAllClass}`)
          .off()
          .on('click', () => {
            $().ptibox.close();
          });

        $(`.${downloadSelectedClass}`).off().on('click', this.downloadSelection);
      },
      itemArray: [
        $.fn.ptibox.generateItemObject(
          markup,
          '',
          '',
          getI18nByKey('DownloadPdf.Title'),
          null,
          270,
          400,
          null,
          false
        )
      ],
      showInfo: 2,
      showPlayButton: false
    });
  }

  /**
   * @returns {void}
   */
  showPrint() {
    const { containerElementID, onlineMagDefaultPopupBorderColour, printSelection } = this;
    const printAllClass = 'js-print-all';
    const printSelectedClass = 'js-print-selected';
    const markup = `
    <div>
      <h2 class="popup-heading">${getI18nByKey('Print.Title')}</h2>
      <div style="display: flex; flex-wrap: wrap; justify-content: space-between;">
        <a href="https://${this.host}/${this.documentName}/${
      this.versionName
    }/Print.htm" target="_blank" rel="noopener noreferrer" data-t="print-all-link" class="popup-action-button mod-print-all ${printAllClass} text-print-button">
          <span>${getI18nByKey('Print.PrintAllPages')}</span>
        </a>
        <button class="popup-action-button mod-print-selected ${printSelectedClass} text-print-button" data-t="print-selected">
          <span>${getI18nByKey('Print.SelectPagesToPrint')}</span>
        </button>
      </div>
    </div>`;

    // @ts-ignore
    $().ptibox({
      cssClass: 'system-modal',
      borderColour: onlineMagDefaultPopupBorderColour,
      callbackOnClose() {
        window.ptiHookupLeftRightArrows(containerElementID);
      },
      callbackOnShow: () => {
        $(`.${printAllClass}`)
          .off()
          .on('click', () => {
            $().ptibox.close();
          });

        $(`.${printSelectedClass}`).off().on('click', printSelection);
      },
      itemArray: [
        $.fn.ptibox.generateItemObject(
          markup,
          '',
          '',
          getI18nByKey('Print.PrintDocument'),
          null,
          270,
          400,
          null,
          false
        )
      ],
      showInfo: 2,
      showPlayButton: false
    });
  }

  /**
   * @returns {void}
   */
  downloadSelection() {
    const objOnlineMagIssue = this;
    const { containerElementID, onlineMagDefaultPopupBorderColour, onlineMagIssueUrl } =
      objOnlineMagIssue;
    const pdfFormClass = 'js-pdf-form';
    const pdfSelectAllClass = 'js-pdf-select-all';
    const markup = `
    <form class="${pdfFormClass}" aria-label="${getI18nByKey('DownloadPdf.SelectPagesToDownload')}">
      <div style="display: flex; justify-content: space-between; margin-bottom: 15px;">
      <h2 class="js-pdf-heading" tabindex="-1" style="margin: 0;">${getI18nByKey(
        'DownloadPdf.Title'
      )}</h2>
          <div class="contents-page-select-all">
            <label for="pdf-select-all" class="select-all-label">${getI18nByKey(
              'DownloadPdf.SelectAll'
            )}</label>
            <input id="pdf-select-all" class="${pdfSelectAllClass} contents-page-select-all-checkbox" type="checkbox" checked="true" >
          </div>
      </div>
      <div>
        ${this.getContentsMarkup(renderCheckboxPage)}
      </div>

      <div style="display: flex; justify-content: center;">
        <button type="submit" class="form-element-button" data-t="pdf-download-selected">${getI18nByKey(
          'DownloadPdf.Title'
        )}</button>
      </div>
    </form>`;

    // @ts-ignore
    $().ptibox({
      cssClass: 'system-modal',
      borderColour: onlineMagDefaultPopupBorderColour,
      callbackOnClose() {
        window.ptiHookupLeftRightArrows(containerElementID);
      },
      callbackOnShow() {
        const $selectAllCheckbox = $(`.${pdfSelectAllClass}`);
        /** @type {HTMLInputElement[]} */
        const allPageCheckBoxes = Array.from(document.querySelectorAll('.contents-page-checkbox'));
        const focusClass = 'mod-focus';

        $(`.${pdfSelectAllClass}`)
          .off()
          .on('change', function onChange(e) {
            const { target } = e;

            if (target instanceof HTMLInputElement) {
              allPageCheckBoxes.forEach(el => (el.checked = target.checked));
            }
          });

        $(`.${pdfFormClass} .js-contents-page-checkbox`)
          .off()
          .on('blur', function onBlur(e) {
            const { target } = e.originalEvent;

            if (target instanceof HTMLInputElement) {
              target.parentElement.classList.remove(focusClass);
            }
          })
          .on('focus', function onFocus(e) {
            delay(100).then(() => {
              const { target } = e.originalEvent;

              if (target instanceof HTMLInputElement) {
                if (target.hasAttribute('data-focus-visible-added')) {
                  target.parentElement.classList.add(focusClass);
                }
              }
            });
          })
          .on('change', function onChange() {
            const headStatus = allPageCheckBoxes[0].checked;
            const allSame = [...allPageCheckBoxes].every(c => c.checked === headStatus);

            if (allSame) {
              $selectAllCheckbox.prop('indeterminate', false);
              $selectAllCheckbox.prop('checked', headStatus);
            } else {
              $selectAllCheckbox.prop('indeterminate', true);
            }
          });

        $(`.${pdfFormClass}`)
          .off()
          .on('submit', handlePopupFormSubmission(onlineMagIssueUrl, 'PDF.pdf'));

        $('.js-pdf-heading').trigger('focus');
      },
      itemArray: [
        $.fn.ptibox.generateItemObject(
          markup,
          '',
          '',
          getI18nByKey('DownloadPdf.Title'),
          null,
          6 * this.thumbnailWidth + 130,
          this.onlineMagFrameHeight - 60,
          null,
          false
        )
      ],
      showInfo: 2,
      showPlayButton: false
    });
  }

  /**
   * @param {number} pageIndex
   * @returns {void}
   */
  closeSearchGotoPage(pageIndex) {
    var elementID = this.containerElementID;
    var suppressFocusActiveElement = true;

    $().ptibox.close(function onClose() {
      window.ptiHookupLeftRightArrows(elementID);
      window.ptiGotoPage(elementID, pageIndex);
    }, suppressFocusActiveElement);
  }

  /**
   * @this {OnlineMag}
   * @param {string} searchTerm
   * @returns {void}
   */
  searchNow(searchTerm, menuID) {
    const searchButton = getDomElement('.js-search-button');
    const isSearching = searchButton.map(el => el.getAttribute('aria-disabled')).option(false);

    if (isSearching) {
      return;
    }

    getDomElement('.js-results-a11y-text').map(
      el => (el.textContent = getI18nByKey('Search.Buttons.Searching'))
    );

    searchButton.map(el => {
      el.textContent = `${getI18nByKey('Search.Buttons.Searching')}...`;
      el.setAttribute('aria-disabled', 'true');
    });

    getSearchResults(this, searchTerm, menuID)
      .then(this.searchResults)
      .catch(err => {
        window.errorReporter.sendError({
          col: 0,
          line: 0,
          message: `${getI18nByKey('Search.Message.SearchFailed')}: ${err.message}`,
          stack: 'No Stack',
          type: 'Error'
        });

        this.searchError(getI18nByKey('Search.Message.SearchError'));
      });
  }

  /**
   * @param {string} errorMessage
   * @returns {void}
   */
  searchError(errorMessage) {
    getDomElement('.js-search-button').map(el => {
      el.textContent = `${getI18nByKey('Search.Buttons.Search')}`;
      el.removeAttribute('aria-disabled');
    });

    getDomElement('.js-results-text').map(el => {
      el.textContent = errorMessage;
      el.classList.add('mod-show');
    });
  }

  /**
   * @param {SearchResults} searchResults
   * @returns {void}
   */
  searchResults(searchResults) {
    const searchText = $('#ptitoolssearchtext').val().toString();
    const a11yTextContainer = getDomElement('.js-results-a11y-text');
    const totalSearchResults = searchResults.reduce((acc, curr) => acc + curr.results.length, 0);

    const resultSingularPlural =
      totalSearchResults === 1
        ? `${getI18nByKey(
            'Search.ResultCount.Single',
            `${totalSearchResults}`,
            `${htmlEncode(searchText)}`
          )}`
        : `${getI18nByKey(
            'Search.ResultCount.Multiple',
            `${totalSearchResults}`,
            `${htmlEncode(searchText)}`
          )}`;
    const hasResults = totalSearchResults > 0;

    const searchResultText = hasResults
      ? `${resultSingularPlural}`
      : `${getI18nByKey('Search.NoResults')}`;

    getDomElement('.js-search-button').map(el => {
      el.textContent = `${getI18nByKey('Search.Buttons.Search')}`;
      el.removeAttribute('aria-disabled');
    });

    getDomElement('.js-results-container').map(el => {
      el.innerHTML = getSearchResultsMarkup(this, searchResults);
    });

    a11yTextContainer.map(el => (el.textContent = searchResultText));

    getDomElement('.js-results-text').map(el => {
      el.textContent = searchResultText;
      el.classList.add('mod-show');
    });
  }

  /**
   * @param {string} html
   * @returns {void}
   */
  _showIssuesModal(html) {
    const {
      containerElementID,
      onlineMagDefaultPopupBorderColour,
      thumbnailHeight,
      thumbnailWidth
    } = this;

    // @ts-ignore
    $().ptibox({
      cssClass: 'system-modal',
      borderColour: onlineMagDefaultPopupBorderColour,
      callbackOnClose() {
        window.ptiHookupLeftRightArrows(containerElementID);
      },
      itemArray: [
        $.fn.ptibox.generateItemObject(
          html,
          '',
          '',
          getI18nByKey('Issues.Title'),
          null,
          thumbnailWidth + 500,
          2 * thumbnailHeight + 100,
          null,
          false
        )
      ],
      showInfo: 2,
      showPlayButton: false
    });
  }

  _getIssueVersions() {
    const errorMessage = getI18nByKey('Issues.Message.VersionListLoadingProblem');

    this.issueList = RemoteData.Loading();
    $().ptibox.showLoading();

    getIssueVersions(this)
      .then(issueList => {
        this.issueList = RemoteData.Success(issueList);
      })
      .catch(_err => {
        this.issueList = RemoteData.Failed(errorMessage);
      })
      .finally(() => {
        $().ptibox.hideLoading();
        RemoteData.match(
          {
            notAsked: noOp,
            loading: noOp,
            success: issues => {
              this._showIssuesModal(getIssueListMarkup(this, issues));
            },
            failed: this._showIssuesModal
          },
          this.issueList
        );
      });
  }

  /**
   * @returns {void}
   */
  showIssues() {
    if (RemoteData.isSuccess(this.issueList)) {
      // @ts-ignore
      this._showIssuesModal(getIssueListMarkup(this, this.issueList.data));
      return;
    }

    this._getIssueVersions();
  }
}

/* eslint-enable prefer-destructuring  */
/**
 * @param {OnlineMag["viewingInterface"]} uiType - Stackable or PageTurn
 * @returns {Promise<OnlineMag["ui"]>}
 */
const lazilyGetUI = uiType => {
  switch (uiType) {
    case STACKABLE:
      return import(/* webpackChunkName: "stackable-ui" */ './stackable-ui').then(
        ({ default: ui }) => ui
      );

    case PAGE_TURN:
      return import(/* webpackChunkName: "pageturn-ui" */ './pageturn-ui').then(
        ({ default: ui }) => ui
      );

    case HTML_PAGE_TURN:
      return import(/* webpackChunkName: "html-pageturn-ui" */ './html-pageturn-ui').then(
        ({ default: ui }) => ui
      );

    case HTML_STACKABLE:
      return import(/* webpackChunkName: "html-pageturn-ui" */ './html-pageturn-ui').then(
        ({ default: ui }) => ui
      );

    case HIGH_CONTRAST:
      return import(/* webpackChunkName: "high-contrast-ui" */ './high-contrast-ui').then(
        ({ default: ui }) => ui
      );

    default:
      throw new Error('Invalid UI');
  }
};

/* eslint-disable no-param-reassign */
/**
 * @param {OnlineMag} pageTurn
 * @returns {void}
 */
const updatePageLayout = pageTurn => {
  pageTurn.isSmallScreen = document.body.clientWidth < constants.SMALL_SCREEN_WIDTH;

  const layout = getLayout(pageTurn);

  if (pageTurn.ui) {
    pageTurn.ui.suppressSound = true;
  }

  if (layout === 'single') {
    window.ptiSinglePageView();
  }

  if (layout === 'double') {
    window.ptiDoublePageView();
  }

  setTimeout(() => {
    if (pageTurn.ui) {
      pageTurn.ui.suppressSound = false;
    }
  }, 800);
};

const calculateAvailableHeight = () => {
  const windowHeight = document.documentElement.clientHeight;
  const toolbars = $('.toolbar:visible');
  const toolbarElementHeight = $('.toolbar').height() ?? 0;
  const toolbarsHeight = toolbars.length > 0 ? toolbarElementHeight * toolbars.length : 0;
  // Get the height of the currently visible menu (this could be the mobile menu or desktop)
  const menuHeight = $('.nav-container:visible').height() ?? 0;
  const cookieBannerHeight =
    document.querySelector('.js-popup-cookie-notice')?.getBoundingClientRect()?.height ?? 0;
  return windowHeight - (menuHeight + toolbarsHeight + cookieBannerHeight);
};

window.switchToolbar = isSinglePageView => {
  const text = isSinglePageView ? getI18nByKey('Menu.DoublePage') : getI18nByKey('Menu.SinglePage');

  delay(500).then(() => $('.js-switch-page-view').text(text));
};

const getPageIDFromIndex = pages => index => view(lensPath(['items', index, 'id']), pages) || -1;

/**
 * @param {Page} page
 * @param {string} imageStyle
 * @returns {string}
 */
const renderContentsPage = (page, imageStyle) => `
  <div class="contents-page-item">
    <a class="contents-page-link"
        href="javascript: ptiCloseContentsGotoPage(null, ${page.pageIndex});"
        data-t="contents-page-${page.pageIndex}">
      <div ${imageStyle}></div>
      <span class="contents-item-link-text">${getI18nByKey('Page')} ${page.pageIndex + 1}</span>
    </a>
  </div>`;

/**
 * @param {Page} page
 * @param {string} imageStyle
 * @returns {string}
 */
const renderCheckboxPage = (page, imageStyle) => `
  <div class="contents-page-item mod-checkbox">
    <label for="contents-page-input-${page.pageIndex}" class="contents-page-label">
      <div ${imageStyle}></div>
      <span class="contents-item-link-text mod-checkbox">${getI18nByKey('Page')} ${
  page.pageIndex + 1
}</span>
    </label>
    <input class="contents-page-checkbox js-contents-page-checkbox" id="contents-page-input-${
      page.pageIndex
    }"
      data-t="checkbox-${page.pageIndex}" data-pageNumber="${
  page.pageIndex + 1
}" type="checkbox" checked="true" >
  </div>`;

/**
 *
 * @param {Page} page
 * @param {string} imageStyle
 * @returns {string}
 */
const renderRadioPage = (page, imageStyle) => `
  <div class="contents-page-item mod-checkbox">
    <label for="contents-page-input-${page.pageIndex}" class="contents-page-label">
      <div ${imageStyle}></div>
      <span class="contents-item-link-text mod-checkbox">${getI18nByKey('Page')} ${
  page.pageIndex + 1
}</span>
    </label>
    <input class="contents-page-checkbox" id="contents-page-input-${
      page.pageIndex
    }" name="share-page-input" data-t="share-page-${page.pageIndex}" data-pageNumber="${
  page.pageIndex + 1
}" ${page.pageIndex === 0 ? 'checked="true"' : ''} type="radio">
  </div>`;

/**
 * @param {string} shareType
 * @param {string} shareTypeText
 * @returns {string}
 */
const renderShareButton = (shareType, shareTypeText) => {
  const loweredType = shareType.toLowerCase();

  return `
    <button onclick="this.form.submitted=this.value;" class="share-button mod-${loweredType} js-share-${loweredType}" name="${loweredType}" value="${loweredType}" type="submit" title="${shareTypeText}" data-t="share-${loweredType}">
      <span class="visually-hidden">${shareTypeText}</span>
    </button>`;
};

/**
 * @param {HTMLInputElement} inputElement
 * @returns {unknown}
 */
const getPageNumberFromDataSet = inputElement => {
  /** @type {?string} */
  const pageNumber = inputElement.dataset.pagenumber;

  if (pageNumber) {
    return Maybe.Just(pageNumber);
  }

  return Maybe.Nothing();
};

/**
 * @param {string} issueUrl
 * @param {string} pathUrl
 * @returns {(e: JQueryEventObject) => void}
 */
const handlePopupFormSubmission = (issueUrl, pathUrl) => e => {
  e.preventDefault();

  if (e.originalEvent.target instanceof HTMLFormElement) {
    const form = e.originalEvent.target;

    /** @type {Array<HTMLInputElement>} */
    const checkboxes = Array.from(form.querySelectorAll('input[type="checkbox"]'));

    /** @type {string} */
    const pageNumbers = checkboxes
      .reduce((acc, el) => (el.checked ? [...acc, el.dataset.pagenumber] : acc), [])
      .join(',');

    const redirectUrl = new URL(`${issueUrl}/${pathUrl}`);

    redirectUrl.searchParams.append('PN', pageNumbers);

    openWindow(redirectUrl.href, '_blank');

    $().ptibox.close();
  }
};

/** @returns {PartialConfig} */
window.ptiGetDefaultConfig = function ptiGetDefaultConfig() {
  return {
    alwaysOpened: false,
    attractMode: false,
    attractModePageEverySeconds: 20,
    attractModeStartAfterSeconds: 60,
    attractModeWaitForVideos: true,
    backgroundComplete: null,
    cacheSize: 10,
    captureEmail: 0,
    coverThumbUrl: '',
    defaultPopupBorderColour: '#000000',
    defaultPopupButtonBGColour: '#000000',
    defaultPopupButtonTextColour: '#FFFFFF',
    disableGUI: false,
    // Can be set from the server (ptiem) and then potentially overwritten by this script
    emailAddress: '',
    emailCookiePath: '/',
    // Can be set from the server (ptiem)
    emailMessageGuid: '',
    employeeNoRequired: false,
    errorMessage: '',
    firstPage: 0,
    flipSound: '02.mp3',
    frameWidth: 0.5,
    features: {
      myAccountV2: false
    },
    hostIncWWW: 'view.pagetiger.com',
    hostV2IncWWW: 'new.view.pagetiger.com',
    jumpToPageOnFirstPageLoad: 0,
    jumpToPageOnFirstPageLoadDelay: 2000,
    loginText: '',
    onLinkClicked: () => {},
    onNavigationRendered: () => {},
    onPagesViewed: () => {},
    pwdRequired: false,
    readerLoginRequired: false,
    referralUrl: '',
    rememberEmail: 0,
    scrollTopOnPaging: true,
    ssoRequired: false
  };
};

/**
 * @param {string} errorMessage
 * @returns {string}
 */
const getBackgroundErrorMessage = errorMessage => `
  <h2>${getI18nByKey('Messages.Oops')}</h2>
  <h1>${getI18nByKey('Messages.ErrorLoadingDocument')}</h1>
  <p>${errorMessage}</p>
`;

/**
 * @param {string} targetContainerID
 * @param {string} errorMessage
 * @returns {void}
 */
const showBackgroundError = (targetContainerID, errorMessage) => {
  $.fn.ptibox.hideLoading();

  const markup = containsHtml(errorMessage) ? errorMessage : htmlEncode(errorMessage);

  $(`#${targetContainerID}`).html(
    `<main class="ptiIssueError" data-t="error-container">${markup}</main>`
  );
};

$(document).ready(function () {
  // @ts-ignore
  window.pageTurn = new OnlineMagIssue(
    window.elementId,
    window.pageTurnConfig,
    window.documentGuid
  );

  $('form input').on('invalid', function (e) {
    handleInvalidFormElement(e);
  });
});
